<template>
  <div class="contract">
     <h5>
       合同操作说明
     </h5>
      <p>1. 请您先下载合同，然后打印成纸质合同。</p>
      <p>2. 盖上您的公章（含骑缝章）。</p>
      <p>3. 把每页扫描成清晰的图片放到同一个文件夹里，然后压缩成压缩包上传到本网站即可。</p>
      <p>4.注意: 请您务必上传甲乙双方用印（含骑缝章）完毕后的合同，否则系统平台不予开课。上传合同时需确保与您下载时的合同主体一致, 如擅自更改合同(包 括但不限于合同主体,附件等)则合同无效,公司将保留追究法律的权利。</p>
  </div>
</template>

<script>
export default {
name: "contracts", // 合同操作
}
</script>

<style lang="scss" scoped>
  .contract{
     //margin-bottom: 20px;
     h5{
       color: #333;
       font-size: 24px;
       font-weight: bold;
       margin-bottom: 10px;
     }
    p{
      font-size: 16px;
      font-weight: 400;
      color: #333;
      padding-bottom: 5px;
    }
  }
</style>