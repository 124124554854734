<template>
  <div class="class">
    <lga-classes></lga-classes>
  </div>
</template>

<script>
import LgaClasses from "@/views/admin/school/classroom/lgaClasses";


export default {
  name: "classroom",
  components: {

    LgaClasses
  },
  data() {
    return {};
  },
  methods: {
    // submitSucceed(){
    //
    // }
  },
};
</script>
<style lang="scss" scoped>
</style>