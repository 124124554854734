<template>
  <div class="list" style="width:100%;height:100%;">
    <div class="class-list">
      <div class="class-item" :class="isHome ? 'class-item-bor' : 'class-item-rad'" v-for="(item, index) in videoList" :key="index">
        <div class="class-item-video" :class="item.fullVideoURL === '' ? 'class-item-bg' : '' " @click="toVideo(item)">
          <img
            :src="item.fullVideoURL"
            alt=""
            v-if="item.fullVideoURL !== ''"
          />
          <!-- <img src="../../assets/img/school/view_null.png" alt="" v-else /> -->
        </div>
        <div class="class-item-txt">
          <h2 :class="item.title != '' ? '' : 'null'" :title="item.title">
            {{ item.title != "" ? item.title : "暂无课程"}}
          </h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      videoList: "",
      curTeacherList:this.teacherList,
      curStuList:this.stuList,
    };
  },
  props:{
    isHome:{
      type: Boolean,
      default:false,
    }
  },
  watch:{
    '$store.state.schoolInfo.atteSchool.id'(newvalue){
      this.findOnlineClass(newvalue);
    }
  },
  mounted() {
    console.log(this.isHome,'isHome')
    if(this.$store.state.userInfo.teacherId){
      let id = this.$store.state.userInfo.teacherId;
      this.findOnlineClassByTeacherId(id);
    }else{
      let id = this.$store.state.schoolInfo.atteSchool.id;
      // 在线课程
      this.findOnlineClass(id);
    }
  },
  methods: {
    // 在线课程 园长账号
    findOnlineClass(id) {
      this.api.school.findOnlineClass(id).then((res) => {
        console.log("在线课堂", res);
        if (res.flag) {
          this.videoList = res.data;
        }
      });
    },
    // 在线课程 老师账号
    findOnlineClassByTeacherId(id) {
      this.api.nAdmin.findOnlineClassByTeacherId(id).then((res) => {
        console.log("在线课堂", res);
        if (res.flag) {
          this.videoList = res.data;
        }
      });
    },
    toVideo(item){  //跳转视频页面
      if(item.title){
        this.getList(item);
      }
    },
    // 老师账号获取教师/学生/视频列表
    getList(onlineclass) {
      let data = {
        schoolId: this.$store.state.userInfo.schoolId,
        teacherId: this.$store.state.userInfo.teacherId,
        gradeId: onlineclass.gradeId,
      };
      this.api.course.findEntryClass(data).then((res) => {
        this.curStuList = res.data.atteStudentList.map((item) => {
          return item.id;
        });
        this.curTeacherList = res.data.atteTeacherList.map((item) => {
          return item.id;
        });

        this.$router.push({
          path: "/onlineClass",
          query: {
            studentIds: this.curStuList.join(","),
            teacherId: this.curTeacherList.join(","),
            unnormalUrl: onlineclass.vedioURL,
            courseId: onlineclass.courseId,
            gradeId: onlineclass.gradeId,
            videoId: onlineclass.videoId,
            videoTitle: onlineclass.title
          },
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.class-list {
  margin-top: 25px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  width:100%;
  .class-item{
    // flex:1;
    width:308px;
    height:192px;
  }
  .class-item-bor:nth-child(1),
  .class-item-bor:nth-child(2){
    background: url('../../assets/img/newschool/Pic_Line_L2.png') no-repeat;
    background-size: cover;
  }
  .class-item-bor:nth-child(3),
  .class-item-bor:nth-child(4){
    background: url('../../assets/img/newschool/Pic_Line_L3.png') no-repeat;
    background-size: cover;
  }
  .class-item-rad{
    width: 340px;
    height: 200px;
    .class-item-video{
      width: 340px;
      height: 200px;
      border-radius: 8px;
      box-shadow: 0px 2px 12px 0px rgba(114, 114, 114, 0.5);
      img{
        border-radius: 8px;
      }
    }
    .class-item-bg{
      border-radius: 8px !important;
    }
    .class-item-txt{
      width:100% !important;
    }
  }
  .class-item-video {
    margin-top:2px;
    margin-left:4px;
    width: 300px;
    height: 188px;
    box-shadow: 0px 2px 18px 0px #710a00;
    cursor:pointer;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .class-item:nth-child(1) .class-item-bg{
    background: url('../../assets/img/newschool/Pic_OnlC_Ks_a.png') no-repeat;
    background-size: cover;
    border-radius: 3px;
    
  }
  .class-item:nth-child(2) .class-item-bg{
    background: url('../../assets/img/newschool/Pic_OnlC_Ks_b.png') no-repeat;
    background-size: cover;
    border-radius: 3px;
  }
  .class-item:nth-child(3) .class-item-bg{
    background: url('../../assets/img/newschool/Pic_OnlC_Ks_c.png') no-repeat;
    background-size: cover;
    border-radius: 3px;
  }
  .class-item:nth-child(4) .class-item-bg{
    background: url('../../assets/img/newschool/Pic_OnlC_Ks_d.png') no-repeat;
    background-size: cover;
    border-radius: 3px;
  }
  .class-item-txt {
    margin-top: -42px;
    margin-left:4px;
    width: 300px;
    text-align:center;
    height: 42px;
    line-height: 42px;
    background: rgba(51, 51, 51, 0.4);
    border-radius: 0px 0px 3px 3px;
    position:relative;
    z-index:0;
    h2 {
      font-size: 20px;
      font-family: Adobe Heiti Std;
      font-weight: bold;
      color: #FFFFFF;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .null {
      color: #FFFFFF;
    }
  }
}
</style>