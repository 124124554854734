<template>
  <!-- 合同框 -->
  <div>
    <el-dialog :visible.sync="visible" center :close-on-click-modal="false">
      <template slot="title">
        <div class="upWT">合同</div>
        <div class="close" @click="close">
        </div>
      </template>
      <div class="hetong">
        <div class="form-pay layui-layer-wrap" id="form-contract" style="text-align: center;">
          <div style="text-align: right">
            <label name="payway" value="2" id="label">
              <span>合同编号: </span>
              <span
                  class="money"
                  id="contract"
                  style="color: black; font-size: 0.12rem">
              {{ contractForm.contractPrefix + contractForm.contractNumber }}
             </span>
            </label>
          </div>
          <div class="contract type">
            <div>
              <h3>
                <strong>全国足球特色幼儿园系统平台用户服务购买协议</strong>
              </h3>
              <div style="text-align: left">
                甲方：小牛启航体育（北京）有限公司 <br/>
                乙方：
                <input
                    type="text"
                    v-model="contractForm.buyName"
                    name="buy_name"
                /><br/>
                甲、乙双方为友好合作，经协商一致，按照国家法律、法规及相关行业政策，签订本协议，以兹共守。<br/>
                一、 服务内容与方式<br/>
                1.甲方通过“全国足球特色幼儿园系统平台”（以下简称“系统平台”，包括但不限于网站、微信公众号、微信小程序、微信插件及其他方式等）向乙方提供“全国足球特色幼儿园线上视频游戏教学课程”——“Let’s
                Get
                Active线上课程”（以下简称“LGA线上课程”），具体服务范围及内容根据乙方选购课程确定。<br/>
                2.甲方通过第
                <el-input-number
                    type="text"
                    name="platform"
                    style="width: 1.7rem"
                    v-model="contractForm.platform"
                    placeholder="请填写数字1、 2、 3"
                    :min="1"
                    :max="3">
                </el-input-number>
                种平台向乙方提供服务：<br/>
                （1）网站（网址：www.prefootball.cn）；<br/>
                （2）微信公众号；<br/>
                （3）其他方式：
                <input
                    type="text"
                    v-model="contractForm.platformVal"
                /><br/>
                3.根据乙方要求，甲方向乙方提供以下服务。<br/>
                (1) 幼儿园基础信息管理体系（KFI） Kindergarten
                Foundational Information<br/>
                (2) 幼儿足球游戏课程体系（课程）Let's Get Active<br/>
                (3) 幼儿足球游戏测试体系（PDA）Physical Data Archives<br/>
                (4) 幼儿足球运动评估体系(PES) Physical Evaluation
                System<br/>
                (5) 小小运动会(KSFA) Kids Sports for All<br/>
                (6) 幼儿足球全国综合量化评估体系(KQA）Kindergarten
                Quantitative Assessment<br/>
                <!--                    根据乙方要求，甲方向乙方提供以下第<input type="text" name="service" placeholder="请填写数字1、 2、 3" min='1' max="3">项服务。<br>-->
                <!--                （1）LGA课程；<br>                                         -->
                <!--                （2）PDA测试；<br>-->
                <!--                （3）其他：  <input type="text" name="service_val"><br>-->
                4.为保障甲方整体用户需求，为广大用户提供更好服务，甲方有权修改服务方式、优化服务版本、升级服务系统；为提高甲方服务水平，甲方有权随时丰富服务内容、范围，优化服务方式，并有权调整系统平台及服务的名称、规格和功能等。<br/>
                二、合同价款与服务期限<br/>
                1.乙方协议代码（即其统一社会信用代码及其法定代表人/授权代表人身份证号码）统一社会信用代码（或其他有效证件代码）为：<input
                  type="text"
                  name="credit_code"
                  v-model="contractForm.creditCode"/>；
                法定代表人/授权代表人为：
                <input
                  type="text"
                  name="legal_person"
                  v-model="contractForm.legalPerson"/>，
                身份证号码为：<input
                  type="text"
                  name="id_number"
                  v-model="contractForm.idNumber"
              />。<br/>
                2.合同总价：<br/>
                （1）本协议服务单价为240.00元/ID/学期。<br/>
                （2）乙方确认向甲方购买<input
                  type="text"
                  name="buy_id_num"
                  disabled=""
                  v-model="contractForm.buyIdNum"
              />个ID账号，共购买<input
                  type="text"
                  name="buy_semester_num"
                  v-model="contractForm.buySemesterNum"
                  disabled=""
              />个学期（一个学期即六个月）服务，自本协议生效之日起算，双方另有约定的以另行约定为准。<br/>
                （3）本协议总价为：240.00 *<input
                  type="text"
                  name="buy_id_num"
                  disabled=""
                  v-model="contractForm.buyIdNum"
              />（ID个数）*<input
                  type="text"
                  name="buy_semester_num"
                  disabled=""
                  v-model="contractForm.buySemesterNum"
              />（学期个数）=<input
                  type="text"
                  name="price"
                  disabled=""
                  v-model="contractForm.price"
              />元（大写金额：<input
                  type="text"
                  name="capital_price"
                  disabled=""
                  v-model="contractForm.capitalPrice"
              />
                元整）。<br/>
                （4）甲方当季<select
                  v-model="contractForm.isDiscount"
                  name="is_discount"
                  id="is_discount"
              >
                <option value="是">是</option>
                <option value="否" selected="">否</option>
              </select
              >（是/否）有优惠政策：<br/>
                <div
                    id="is_show"
                    v-show="contractForm.isDiscount == '是'">
                  如甲方当季有优惠政策，本协议总价最终折算为￥
                  <el-input-number
                      id="convert_price"
                      type='number'
                      :min="0"
                      :step="0.11"
                      v-model="contractForm.convertPrice">
                  </el-input-number>
                  元（大写金额：
                  <input
                      type="text"
                      name="capital_convert_price"
                      disabled=""
                      v-model="contractForm.capitalConverPrice"
                  />元整）；
                  如甲方当季无优惠政策，按照本协议第二条第2款第（3）项之约定执行。
                </div>
                <br/>
                3.付款方式：<br/>
                乙方自本协议生效后 五个工作日
                内，向甲方一次性支付本协议总价款。<br/>
                三、双方权利与义务<br/>
                1.甲方负责提供服务内容中的课程和教材，并保证所有内容均是正版的、拥有合法权利或合法授权的。保证绝对不会侵犯任何第三方的著作权、商标权、肖像权、姓名权、名誉权、隐私权或者其他合法权益。<br/>
                2.甲方将采取合理措施保护乙方合规性操作数据、帐户、密码等信息安全，但如因乙方及其管理人员及第三方原因导致信息丢失或泄露的，与甲方无关。<br/>
                3.甲方有权自行决定对服务平台的版本、功能进行变更、升级、优化，有权对服务方式、服务内容进行丰富与优化。乙方同意，甲方无需因上述行为特意通知乙方，并对乙方及乙方管理人员及任何第三人不承担任何责任。<br/>
                4.甲方自本协议生效之日起 五
                个工作日内为乙方开通相应账号、提供选购服务。如系乙方原因（包括但不限于乙方违约、乙方提供资料或信息存在问题等）导致甲方未能如期为乙方开通账号或提供服务的，由乙方自行承担，甲方服务自本协议到期后自动终止，不予顺延；如系甲方无正当理由未能如期为乙方开通账号并提供服务的，甲方服务相应顺延，服务时间自甲方实际提供服务之日起算。<br/>
                5.乙方承诺本协议项下的服务只用于非商业用途。承诺未经甲方书面许可，不对甲方提供的服务及其他自甲方获悉的任何信息或成果，进行复制、出租、出借、出售、或通过其他方式转让、披露于第三人，或通过剪切、编辑、录制及其他方式生成衍生作品。否则，无论是否基于商业目的，均应向甲方承担违约责任，并赔偿甲方一切损失。<br/>
                6.乙方不得基于商业目的模仿甲方的产品和/或服务；不得复制或模仿甲方的设计理念、界面、功能和图表；未经甲方许可不得对甲方产品/服务的内容进行修改或制造其他派生产品。<br/>
                7.乙方不得利用甲方平台及提供产品/服务进行任何违法或不当的活动，不得在系统平台发表任何违法或不当言论。<br/>
                8.甲方仅向乙方提供产品/服务。乙方在组织内部人员学习过程中，应自行对其内部人员、参与人员、管理人员的安全负责，如有安全问题与甲方无关。<br/>
                9.乙方按照实名制为其学员购买ID账号及对应服务，每个ID账号仅由实名注册学员单独使用，不可多学员重复使用，否则，乙方应按照实际使用学员人数（即应购买ID个数）向甲方补充相应服务费用，并支付服务费用的30%作为违约金。<br/>
                <!-- 10.乙方如要求开具发票，可自行在“www.prefootball.cn-My
                幼儿园- 费用管理-发票”，向甲方申请开具正规增值税发票。<br/> -->
                <div>
                  <p>10.乙方开票信息</p>
                  <p>发票单位名称：<input type="text" placeholder="请输入发票单位名称" v-model="contractForm.pchoolName"></p>
                  <p>统一社会信用代码：<input type="text" disabled v-model="contractForm.creditCode"></p>
                  <p>开户行名称：<input type="text" placeholder="请输入开户行名称" v-model="contractForm.bankName"></p>
                  <p>开户行账号：<input type="text" placeholder="请输入开户行帐号" v-model="contractForm.bankCount"></p>
                  <p>联系电话：<input type="text" placeholder="请输入联系电话" v-model="contractForm.mobile"></p>
                  <p>联系地址：<input type="text" placeholder="请输入联系地址" v-model="contractForm.address"></p>
                </div>
                11.乙方在成功购买服务后，所支付的服务费用均不作退返。但在下列情况下，甲方同意将乙方订购费用中的剩余款项无息退返：<br/>
                （1）非因乙方原因，甲方无正当理由终止提供服务的；<br/>
                （2）存在其他甲方特别声明退款情形的。<br/>
                12.甲方现行免费提供的产品/服务，不应视为甲方放弃收费的权利。甲方随时有权取消免费服务，并收取相应费用。<br/>
                13.甲方有权根据实际情况随时单方调整费用标准及收费方式，并公示于系统平台，而无需获得乙方同意。若调整日前乙方已经订购服务并已全额支付费用的，已购买的服务不受影响。<br/>
                14.乙方应按照本协议约定及系统平台用户服务协议、甲方声明及规则、补充协议等规定，在授权范围内享受服务，如乙方违反相关约定的，应向甲方承担违约责任，并赔偿甲方一切损失。如因乙方原因，导致第三方侵害甲方合法权益的，乙方与该第三方向甲方承担连带责任；如因乙方原因导致甲方向第三方进行赔偿的，甲方有权向乙方追偿。所指损失，包括但不限于甲方的直接损失、间接损失、逾期利益损失、为及时止损所支付的合理费用、因乙方侵权或违约行为向第三方所支付的费用，以及为维权所支付调查费、保全费、鉴定费、诉讼费、律师费、差旅费等全部费用。<br/>
                四、违约责任<br/>
                1.甲方应如期为乙方提供服务，逾期未能提供的，按照本协议第三条第4款之约定承担法律责任。<br/>
                2.乙方应按照本协议之约定及时履行付款义务，逾期付款的，每逾期一日，按照本协议总价款的
                5
                ‰向甲方支付违约金；逾期超过三十日的，甲方有权解除本协议，同时要求乙方支付违约金。<br/>
                五、知识产权保护与保密条款<br/>
                1.甲方向乙方所提供的一切内容（包括但不限于文字、图片、音频、视频、图表、界面设计、版面框架、有关数据、电子文档、软件或应用程序等）的一切著作权、商标权、专利权、商业秘密等知识产权，均归甲方所有，乙方仅在授权范围内享有使用权。<br/>
                2.甲方需采取合理的措施保护乙方的信息。除法律法规规定的情形外，未经乙方许可甲方不得向第三方公开、透露用户信息。但为保障服务质量与效率、便于行政管理与监督，乙方同意甲方向预先选定的合作方及教育主管部门提供乙方信息。<br/>
                3.乙方应就其自甲方所获取的一切产品、服务及内容予以保密，未经甲方书面许可，无论其是否存在商业目的，均不得以任何形式向第三方予以披露，否则应向甲方承担违约责任，并赔偿甲方一切损失。<br/>
                4.各方对在履行协议中获得的商业机密和技术秘密负保密责任。但出现下列任一情况时，甲方有权披露乙方信息：<br/>
                （1）
                应人民银行或银监会、证监会、保监会等金融业监管机构要求的；<br/>
                （2）
                应公安机关、人民法院、人民检察院、教育部或有权行政机关要求的；<br/>
                （3）根据法律规定甲方应当向第三方提供信息的其他情形。<br/>
                5.各方保证其本身及其雇员、代理人、管理人员履行本协议保密义务，保密期限为永久。<br/>
                六、争议解决<br/>
                甲乙双方发生任何纠纷或争议，首先应友好协商解决；协商不成的，任何一方有权向甲方所在地有管辖权的人民法院提起诉讼。<br/>
                七、其他说明<br/>
                1.本协议未尽事宜，由双方另行协商并签订书面补充协议确定。补充协议系本协议的组成部分，其约定与本协议不一致的，以补充协议为准。<br/>
                2.系统平台用户服务协议、甲方声明与使用规则等是本协议的有效组成部分，与本协议具有同等法律效力。<br/>
                3.本协议原则上采用线上签订模式，以系统平台上传备案协议内容为准，甲乙双方可自行下载备案协议，下载文本具有同等法律效力。<br/>
                4.本协议自甲乙双方签字或盖章之日起生效。<br/>
                （以下无正文）<br/>

                甲方（盖章）：
                <!--                    <div style="position: relative;z-index: 1;display: inline-block;width: 190px"><img src="/assets/img/stamp.png" style="width: 110px;height: 110px;margin-left: 30px" alt=""><span style="position: absolute;z-index:2;left: 5px;top: 44px;">小牛启航体育（北京）有限公司</span></div>-->
                <br/>
                法定代表人/授权代理人（签字）：
                <!--                    <img src="/assets/img/sign.png" style="width: 110px;height: 110px;margin-left: 30px" alt=""> -->
                <br/>
                <input
                    type="text"
                    name="one_year"
                    v-model="jfDate[0]"
                    disabled=""
                />年<input
                  type="text"
                  name="one_month"
                  disabled=""
                  v-model="jfDate[1]"
              />月<input
                  type="text"
                  name="one_date"
                  disabled=""
                  v-model="jfDate[2]"
              />日<br/>

                乙方（盖章）：<br/>
                法定代表人/授权代理人（签字）：<input
                  type="text"
                  name="bottom_legal_person"
                  style="border: 0; border-bottom: 1px solid black"
                  disabled=""
              />
                <br/>
                <input
                    type="text"
                    v-model="yfDate[0]"
                    name="two_year"
                />年<input
                  type="text"
                  name="two_month"
                  v-model="yfDate[1]"
              />月<input
                  type="text"
                  v-model="yfDate[2]"
                  name="two_date"
              />日<br/>
              </div>
            </div>
          </div>
          <div class="clause">
            <label class="agree">
              <input
                  tabindex="3"
                  type="hidden"
                  id="IsRemember"
                  name="IsRemember"
                  value="false"
                  style="margin: 0"/>
              <label
                  class="tab"
                  :class="{ active: isCheck }"
                  @click="isCheck = !isCheck">
                <span class="onClick"></span>
                我已阅读并同意
              </label>
              《全国足球特色幼儿园系统平台用户服务购买协议》

            </label>
          </div>
          <div class="text-center action tab" style="text-align:center">
            <div class="btn btn-buy" @click="paySubmit">确认提交</div>
          </div>
        </div>
        <span class="layui-layer-resize"></span>
      </div>
    </el-dialog>
    <!-- 支付框 v-if="$store.state.buyStep == 3"-->

    <el-dialog :visible.sync="piscvisible" center :close-on-click-modal="false">
      <template slot="title">
        <div class="upWT">微信支付</div>
        <div class="close" @click="piscvisibleClose">
        </div>
      </template>
      <div>
        <div class="form-pay" id="form-pay">
          <div class="cont text-center">
            <div class="tit">微信扫一扫付款(元)</div>
            <div class="money" id="payMoney">
              {{ contractForm.convertPrice || contractForm.price }}
            </div>
            <div class="code" style="text-align: center;
    margin-top: .2rem;">
              <div class="qrcode img" ref="qrCodeUrl"></div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";

export default {
  name: "contractPop",
  props: {
    contract: {
      type: Object,
      default() {
        return {}
      }
    },
    contracttext: Object,
  },
  data() {
    return {
      visible: true,
      payShow: true,
      //获取user列表
      userList: '',
      piscvisible: false,
      playstatus: false,
      semesterList: [],
      paySemester: [], // 选中班级,
      contractForm: {
        // 合同内容
        buyIdNum: "", // 学生人数
        semesterIds: [], //学期
        buyName: "", //购买人
        platform: "", // 平台类型  1网站  2微信公众号 3其他方式
        platformVal: "", // 其他方式内容
        serviceVal: "", // 其他服务方式
        creditCode: "", // 信用代码或其他有效证件代码
        legalPerson: "", // 乙方法定代表人
        idNumber: "", // 身份证号
        buySemesterNum: "", // 选中学期数
        price: 0, //总价
        capitalPrice: "", //大写金额
        isDiscount: "否", // 是否有优惠政策 是/否
        convertPrice: "", // 最终折算金额
        capitalConverPrice: "", // 大写折算金额
        firstSignTime: "", // 甲方签字日期
        secondSignPerson: "", // 乙方签字日期
        payType: "", // 付款方式
        pchoolName: '',  //园所名称
        bankName: '',  //开户行名称
        bankCount: '', //开户行账号
        mobile: '',  //联系电话
        address: '', //联系地址
      },
      jfDate: ["", "", ""], // 甲方日期
      yfDate: ["", "", ""], // 乙方日期
      isCheck: false, // 是否同意条款

      //qrcode 微信二维码获取
      qrCodeInfo: {
        outtradeno: "",
        totalFee: "",
        url: "",
      },

      time: 900, //查询订单倒计时
      timer: "", // 结束时间

      semesters: sessionStorage.getItem("buySemesterIds"),
      buyPrice: 0,
    };
  },

  computed: {},

  watch: {
    "contractForm.price": {
      handler: function (newVal) {
        this.contractForm.capitalPrice = this.common.convertCurrency(newVal);
      },
    },
    "contractForm.convertPrice": {
      handler: function (newVal) {
        this.contractForm.capitalConverPrice =
            this.common.convertCurrency(newVal);
      },
    },
  },
  methods: {
    show() {
      this.visible = true
    },
    payBox() {
      this.payShow = true
    },
    hide() {
      this.visible = false
    },
    getPrice() {
      this.api.buy.priceTemplate("12").then((res) => {
        if (res.flag) {
          this.buyPrice = res.data.price;
        }
      });
    },
    getSemesterList1() {
      this.api.buy
          .findSemesterList({schoolId: this.$store.state.userInfo.schoolId})
          .then((res) => {
            this.semesterList = res.data;
          });
    },
    getSemesterList2() {
      this.api.buy
          .findSemesterListByIds({semesters: this.semesters})
          .then((res) => {
            this.semesterList = res.data;
          });
    },
    openStep2() {
      if (!this.contractForm.paySemester.length) {
        this.$notice({
          message: "请选择学期",
        }).isShow();
        return;
      }
      if (!this.contractForm.buyIdNum) {
        this.$notice({
          message: "请输入学员人数",
        }).isShow();
        return;
      }
      if (this.contractForm.payType === "") {
        this.$notice({
          message: "请选择付款方式",
        }).isShow();
        return;
      }
      this.api.buy
          .addContractInfo({schoolId: this.$store.state.userInfo.schoolId})
          .then((res) => {
            if (res.flag) {
              this.contractForm.contractNumber = res.data.contractNumber;
              this.contractForm.contractPrefix = res.data.contractPrefix;
              this.contractForm.id = res.data.id;
              this.contractForm.schoolId = res.data.schoolId;
              this.contractForm.updatetime = res.data.updatetime;
              this.$store.commit("setBuyStep", 2);
            } else {
              this.$notice({
                message: res.message,
              }).isShow();
            }
          });
    },
    //合同提交
    paySubmit() {
      let that = this;
      if (!this.contractForm.buyName) {
        this.$notice({
          message: "请填写乙方姓名",
        }).isShow();
        return;
      }
      if (!this.contractForm.buyName) {
        this.$notice({
          message: "请填写乙方姓名",
        }).isShow();
        return;
      }
      if (!this.contractForm.platform) {

        this.$notice({
          message: "请填写进入平台方式,1、2、3",
        }).isShow();
        return;
      }
      if (this.contractForm.platform == 3 && !this.contractForm.platformVal) {
        this.$notice({
          message: "请填写进入平台其他方式",
        }).isShow();
        return;
      }
      if (!this.contractForm.creditCode) {
        this.$notice({
          message: "请填写统一社会代码",
        }).isShow();
        return;
      }
      if (!this.contractForm.legalPerson) {
        this.$notice({
          message: "请填写法定代表人",
        }).isShow();
        return;
      }
      if (!this.contractForm.idNumber) {
        this.$notice({
          message: "请填写身份证号码",
        }).isShow();
        return;
      } else {
        if (!this.common.validateIdCard(this.contractForm.idNumber)) {
          this.$notice({
            message: "请输入正确格式身份证号",
          }).isShow();
          return
        }
      }
      if (this.contractForm.isDiscount == "是" && !this.contractForm.convertPrice) {
        this.$notice({
          message: "请填写最终折算金额",
        }).isShow();
        return;
      }
      // if (!this.contractForm.pchoolName) {
      //   this.$notice({
      //     message: "请填写发票单位名称",
      //   }).isShow();
      //   return;
      // }
      // if (!this.contractForm.bankName) {
      //   this.$notice({
      //     message: "请填写开户行名称",
      //   }).isShow();
      //   return;
      // }
      // if (!this.contractForm.bankCount) {
      //   this.$notice({
      //     message: "请填写开户行账号",
      //   }).isShow();
      //   return;
      // }
      // if (!this.contractForm.mobile) {
      //   this.$notice({
      //     message: "请填写联系电话",
      //   }).isShow();
      //   return;
      // }
      // if (!this.contractForm.address) {
      //   this.$notice({
      //     message: "请填写联系地址",
      //   }).isShow();
      //   return;
      // }
      if (!this.yfDate[0] || !this.yfDate[1] || !this.yfDate[2]) {
        this.$notice({
          message: "请填写签字日期",
        }).isShow();
        return;
      }
      if (!this.isCheck) {
        this.$notice({
          message: "请先阅读购买协议",
        }).isShow();
        return;
      }
      this.contractForm.payType = this.contracttext.payWay
      this.contractForm.price = this.contracttext.price
      let data = {
        ...this.contractForm,
      };
      //data.semesterIds = this.paySemester.join(",");
      data.semesterIds = this.contracttext.semester.join(",");
      data.firstSignTime = new Date(this.jfDate.join("-")).getTime() / 1000;
      data.secondSignPerson = new Date(this.yfDate.join("-")).getTime() / 1000;
      data.convertPrice = parseInt(data.convertPrice);

      this.api.buy.updateContractOrder(data).then((res) => {

        if (res.flag) {

          //  this.$store.commit("setRefres", !this.$store.state.refreshList);
          // // console.log('!this.$store.state.refreshList',!this.$store.state.refreshList);

          this.qrCodeInfo.outtradeno = res.data.outtradeno;
          this.qrCodeInfo.totalFee = res.data.total_fee;
          //合同弹窗隐藏
          that.visible = false;
          // self.$emit('successData',true)
        }
        // 付款方式为微信时调用qrco
        if (data.payType == "1") {
          that.piscvisible = true;

          let data = {
            outtradeno: this.qrCodeInfo.outtradeno,
            totalFee: this.qrCodeInfo.totalFee,
          };
          console.log('支付data', data)

          // return false
          this.api.buy.createNative(data).then((result) => {
            if (result.flag) {
              this.$nextTick(() => {
                setTimeout(() => {
                  new QRCode(this.$refs.qrCodeUrl, {
                    text: result.data.code_url,
                    width: 150,
                    height: 150,
                    colorDark: "#000000",
                    colorLight: "#ffffff",
                    correctLevel: QRCode.CorrectLevel.H,
                  });
                }, 500);
              });
              // 创建轮寻 判断是否付款成功 成功后提示
              // 创建倒计时 5s 调用一次查询
              let num = 0;
              this.timer = setInterval(() => {
                num = num + 1;
                this.time = this.time - 1;
                if (num === 5) {
                  this.api.buy.payOrder({outtradeno: this.qrCodeInfo.outtradeno})
                      .then((res) => {
                        if (res.data.payStatus === "paid") {
                          clearInterval(this.timer);
                          this.$notice({
                            type: "success",
                            message: "支付成功",
                          }).isShow();
                          this.$emit('close', false);
                          this.$store.commit("setOpenBuy", false);
                          this.$store.commit("setRefres", !this.$store.state.refreshList);
                          sessionStorage.removeItem("buySemesterIds");
                        }
                      });
                  num = 0;
                }
              }, 1000);
            } else {
              this.$notice({
                message: result.message,
              }).isShow();
            }
          });
        } else {
          this.$notice({
            type: "success",
            message: "请及时上传凭证",
          }).isShow();
          // 否则跳转订单详情 上传支付凭证
          that.$emit('submitPop', true);
          this.$emit('close', false);
          this.$store.commit("setOpenBuy", false);
          // sessionStorage.removeItem("buySemesterIds");
          // this.$router.push({
          //   path: "admin/classroom/classIndex",
          // });
          //location.reload();
          this.$store.commit("setRefres", !this.$store.state.refreshList);
          // console.log('!this.$store.state.refreshList',!this.$store.state.refreshList);
        }


      });
    },


    close() {
      this.visible = false
      this.$emit('close', this.visible)
      this.$store.commit("setOpenBuy", false);
      sessionStorage.removeItem("buySemesterIds");
    },


    piscvisibleClose() {
      this.piscvisible = false
      this.$emit('close', this.piscvisible)
      this.$store.commit("setOpenBuy", false);
      sessionStorage.removeItem("buySemesterIds");
    }
  },
  destroyed() {
    clearInterval(this.timer);
  },
  mounted() {
    let that = this;
    this.$parent.getContract().then((res) => {
      that.contractForm = res;
      that.contractForm.buyIdNum = that.contracttext.number
      that.contractForm.buySemesterNum = that.contracttext.semester.length;
      that.contractForm.price = that.contracttext.price;
    });

    this.getPrice();
    if (!this.semesters) {
      this.getSemesterList1();
    } else {
      this.getSemesterList2();
      this.semesters.split(",").forEach((item) => {
        this.contractForm.semesterIds.push(parseInt(item));
      });
      //this.contractForm.buySemesterNum = this.paySemester.length;
    }
    let date = new Date();
    this.jfDate[0] = date.getFullYear().toString();
    this.jfDate[1] = (date.getMonth() + 1).toString();
    this.jfDate[2] = date.getDate().toString();
  },
}
</script>

<style lang="scss" scoped>
.tit {
  text-align: center;
  color: #333;
  font-size: 16px;
  font-weight: 500;
}

#payMoney {
  text-align: center;
  color: #cb1b31;
  font-weight: bold;
  font-size: 20px;
  margin-top: 15px;
}

.qrcode {
  //width: 250px;
  //margin:  0 auto;
  //text-align: center;
  display: inline-block;

  canvas img {
    margin: 15px auto 0 auto !important;
  }
}

.hintMailbox {
  font-size: 14px;
  font-weight: 400;
  color: #CB1B32;
  text-align: center;
}

.btn {
  width: 100px;
  height: 38px;
  //background: none;
  //border:none;
  border: 1px solid #BA3037;
  border-radius: 6px;
  background: #fff;
  color: #BA3037;
  font-size: 18px;
  margin: 0 25px;
}

.active {
  background: linear-gradient(232deg, #B93037, #EF525B);
  box-shadow: 0 3px 6px 0 rgba(255, 67, 77, 0.33);
  color: #fff;
  border: 1px solid transparent;
}

.contract input:disabled {
  background: rgb(245, 246, 248);
}

/deep/ #convert_price {
  .el-input__inner {
    width: 180px !important;
  }
}

/deep/ .el-input__inner {
  width: 170px !important;
}

/deep/ .el-dialog--center {
  width: 610px;

  .el-form-item {
    margin-bottom: 20px;
  }
}

/deep/ .el-form-item__label {
  color: #333;
  font-size: 16px;
  line-height: 45px;
  margin-right: 14px;
}

/deep/ .el-input {
  width: 400px;
  display: inline;
}

/deep/ .el-input--suffix {
  width: 140px;
  color: #333;

}

/deep/ .el-input__inner {
  width: 400px;
  border: 1px solid #ddd;
  border-radius: 10px;
}

/deep/ .el-dialog__header {
  padding: 0;
  position: relative;
}

/deep/ .el-dialog__headerbtn {
  top: 0.15rem;
  opacity: 0;
}

.upWT {
  height: 50px;
  line-height: 50px;
  background: #B93138;
  color: #fff;
}

.close {
  position: absolute;
  top: 16px;
  right: 20px;
  width: 15px;
  height: 15px;
  background: url(../../../../assets/img/course/Icon_tc_close.png) no-repeat;
  background-size: cover;
  z-index: 999999;
  cursor: pointer;
}

/deep/ .el-input__suffix {
  line-height: .5rem;
}

.contract {
  height: 400px;
  margin-top: 20px;
  overflow-x: auto;
  background: rgb(245, 246, 248);
  color: #228ded;
  padding: 15px;

  input {
    background: #fff;
    border: 1px solid #ddd;
    padding: 0 5px;
  }
}

label.active {
  color: #fff;
  background: #228ded;
}

//label.active::after {
//  background: #cb1b31;
//  border: solid 1px #cb1b31;
//}


.agree {
  color: #cb1b31;
  font-size: 20px;
  margin-left: 40px;
  font-weight: 700;
}

.clause {
  position: relative;
  height: 40px;
  margin-top: 20px;
}

.onClick {
  position: absolute;
  top: 0;
  left: 60px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #eee;
  border: 1px solid #ddd;
  box-sizing: border-box;
  transition: all .3s;
}

label.active .onClick {
  background: #cb1b31;
  border: solid 1px #cb1b31;
}

.btn-buy {
  background: #cb1b31;
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  border: 0;
  padding: 0 6px;
  height: 60px;
  line-height: 60px;
  border-radius: 5px;
  display: inline-block;
  margin-top: 20px;
  box-shadow: none;
  width: 132px;
  margin-bottom: 0;
}
</style>