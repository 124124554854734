<template>
  <div class="class">
    <div class="videoList">
      <div class="videoCenter">
        <div class="videoText">
          <h4>在线课堂</h4>
          <span @click="goClassPre">
            一键上课
            <!-- <router-link :to="{ path: '/admin/classroom/onlineclass' }">一键上课</router-link> -->
          </span>
        </div>
        <video-list></video-list>
      </div>
    </div>
    <div class="courseContent courseAll">
      <div class="courseTitle">
        <div class="dis_flex">
          <h6 class="lagTitle">课程管理</h6>
          <span class="upload" @click="showDialog">立即购买</span>
        </div>

        <!--课程购买--->

        <div style="clear: both"></div>
        <div class="course-fun">
          <div :class="courseChosen === 0 ? 'active_box bg_box' : 'active_box'">
            <div
              class="courseNav activePic"
              @click="
                courseChosenstatus(0);
                activeTabName = 'first';
              "
            >
              <span>LGA</span>
              <p>幼儿体育游戏课程</p>
            </div>
          </div>
          <div :class="courseChosen === 1 ? 'active_box bg_box' : 'active_box'">
            <div
              class="courseNav activePic"
              @click="
                courseChosenstatus(1);
                activeTabName = 'first';
              "
            >
              <span>PGS</span>
              <p>专题游戏训练课程</p>
            </div>
          </div>

          <div :class="courseChosen === 2 ? 'active_box bg_box' : 'active_box'">
            <div
              class="courseNav activePic"
              @click="
                courseChosenstatus(2);
                activeTabName = 'first';
              "
            >
              <span>PGL</span>
              <p>幼儿体育游戏库</p>
            </div>
          </div>

          <div :class="courseChosen === 3 ? 'active_box bg_box' : 'active_box'">
            <div
              class="courseNav activePic"
              @click="
                courseChosenstatus(3);
                activeTabName = 'first';
              "
            >
              <span>PHG</span>
              <p>幼儿体育家庭课程</p>
            </div>
          </div>

          <div :class="courseChosen === 4 ? 'active_box bg_box' : 'active_box'">
            <div
              class="courseNav activePic"
              @click="
                courseChosenstatus(4);
                activeTabName = 'first';
              "
            >
              <p>AI互动课堂</p>
            </div>
          </div>
        </div>
        <div class="courseCenter">
          <div class="coursText">
            <div class="" v-if="courseChosen === 0">
              <div class="lga_center">
                <div>
                  <h5 class="title">幼儿系列课程</h5>
                  <p class="text-center">
                   LGA幼儿体育游戏课程以足球游戏为主题，以英足总青少年发展管理体系为基础，遵循英国国家级幼儿园学前教育体系，从英国引入的原创情景式线上互动游戏课程。该课程采用《全国足球特色幼儿园游戏活动指南》中 的“6+1”主题模式，幼儿可以直接观看，教师在授课过程中无需进行过多讲解，任何人都可以上一堂完美的幼儿体育课程。
                  </p>
                  <p class="text-center">
                    LGA幼儿体育游戏课程共<span>96</span>节，小班、中班、大班每学年各<span>32</span>节，
                    每周1节，每节课程
                    <span>30~40</span>分钟。老师既可以重复上课，也可以
                    每周与其他类型的体育课程进行结合。
                  </p>
                </div>
              </div>
              <div class="padding_40 display_box">
                <div class="dis_time_box">
                  <img
                    src="../../../../assets/img/product/Pic_LGA_A.png"
                    alt=""
                  />
                </div>
                <div class="dis_time_box">
                  <img
                    src="../../../../assets/img/product/Pic_LGA_B.png"
                    alt=""
                  />
                </div>
                <div class="dis_time_box">
                  <img
                    src="../../../../assets/img/product/Pic_LGA_C.png"
                    alt=""
                  />
                </div>
                <div class="dis_time_box">
                  <img
                    src="../../../../assets/img/product/Pic_LGA_D.png"
                    alt=""
                  />
                </div>
              </div>
              <div class="padding_40">
                <div class="title">课程特色</div>
                <p class="text_center">
                  LGA幼儿体育游戏课程的特点分别是简化教学、互联网教学和情景融入。简化教学是指课程提供视频和教案，让老师的授课更加方便简洁，同
                  时还可以提高老师的体育教学能力。互联网教学是指标准全国统一，点击一键上课就可以进行科学、合理的幼儿体育课程。情景融入是指每节
                  课结合一个场景，幼儿可以扮演角色、开展游戏，达到全面发展的目的。
                </p>
              </div>
              <div class="padding_40 display_box">
                <div>
                  <img
                    src="../../../../assets/img/product/Pic_LGA_JHJX.png"
                    alt=""
                    class="imageP"
                  />
                </div>
                <div>
                  <img
                    src="../../../../assets/img/product/Pic_LGA_HLWJX.png"
                    alt=""
                    class="imageP"
                  />
                </div>
                <div>
                  <img
                    src="../../../../assets/img/product/Pic_LGA_QJRR.png"
                    alt=""
                    class="imageP"
                  />
                </div>
              </div>
              <div class="padding_40 line_minheight"></div>
            </div>
            <div class="" v-if="courseChosen === 1">
              <div class="lga_center PGS_center">
                <div>
                  <h5 class="title">教师系列课程</h5>
                  <p class="text-center">
                    PGS专题游戏训练课程为面向教师的视频课程，授课教师观看并理 解课程内容后，即可以独立在室内或室外进行幼儿体育课程。在本 系列课程中融入了更多的足球元素，每节课程中足球游戏的占比更 多。本课程围绕着跑动、跳跃、敏捷、协调、平衡、力量、物体控 制（手）、物体控制（脚）八个主题进行体育教学。
                  </p>
                  <p class="text-center">
                   PGS专题游戏训练课程共计<span>3</span>套，每套课程各<span>8</span>节，第三套课程增 设课程简介和课程总结。每节课程
                    <span>5~8</span>分钟。配以相应的备课教 案，教师可以根据园所具体情况在室内或室外开展课程。
                  </p>
                </div>
              </div>
              <div class="padding_40 display_box">
                <div class="dis_time_box">
                  <img
                    src="../../../../assets/img/product/Pic_PGS_A.png"
                    alt=""
                  />
                </div>
                <div class="dis_time_box">
                  <img
                    src="../../../../assets/img/product/Pic_PGS_B.png"
                    alt=""
                  />
                </div>
                <div class="dis_time_box">
                  <img
                    src="../../../../assets/img/product/Pic_PGS_C.png"
                    alt=""
                  />
                </div>
                <div class="dis_time_box">
                  <img
                    src="../../../../assets/img/product/Pic_PGS_D.png"
                    alt=""
                  />
                </div>
              </div>
              <div class="padding_40">
                <div class="title">课程特色</div>
                <p class="text_center">
                  PGS专题游戏训练课程的特点分别是视频教案、进阶课程和贴近实际。视频教案是指利用视频的方式，明确告知教师教学环节和游戏内容，同 时配以文字和图示标注，帮助教师清晰理解授课内容。进阶课程是指本课程相对LGA幼儿体育游戏课程难度更大，适合有教学基础、掌握教学 方法的教师使用。贴近实际是指课程内容均源于国内一线教师，能够更加合理的解决国内幼儿体育教师遇到的实际问题。
                </p>
              </div>
              <div class="padding_40 display_box">
                <div>
                  <img
                    src="../../../../assets/img/product/Pic_PGS_SPJA.png"
                    alt=""
                    class="imageP"
                  />
                </div>
                <div>
                  <img
                    src="../../../../assets/img/product/Pic_PGS_JJKC.png"
                    alt=""
                    class="imageP"
                  />
                </div>
                <div>
                  <img
                    src="../../../../assets/img/product/Pic_PGS_TJSJ.png"
                    alt=""
                    class="imageP"
                  />
                </div>
              </div>
              <div class="padding_40 line_minheight"></div>
            </div>
            <div class="" v-if="courseChosen === 2">
              <div class="lga_center pgl_center">
                <div>
                  <h5 class="title">教师系列课程</h5>
                  <p class="text-center">
                    PGL幼儿体育游戏库是以每一个体育游戏作为单元，按照不同的主 题进行收集和整理。教师在遵循幼儿体育教学原则的前提下，按照 一节课程的标准环节，可以任意选择游戏单元进行填充，自由“组 装”出一节幼儿体育课程。
                  </p>
                  <p class="text-center">
                   PGL幼儿体育游戏库按照<span>7</span>个主题进行分类，共计<span>405</span>个游戏单元。 每个游戏单元均配以视频和教案，便于教师观看。不限制教师的使用次数，教师每天都可以生成不同的体育活动教案。
                  </p>
                </div>
              </div>
              <div class="padding_40 display_box">
                <div class="dis_time_box">
                  <img
                    src="../../../../assets/img/product/Pic_PGL_A.png"
                    alt=""
                  />
                </div>
                <div class="dis_time_box">
                  <img
                    src="../../../../assets/img/product/Pic_PGL_B.png"
                    alt=""
                  />
                </div>
                <div class="dis_time_box">
                  <img
                    src="../../../../assets/img/product/Pic_PGL_C.png"
                    alt=""
                  />
                </div>
                <div class="dis_time_box">
                  <img
                    src="../../../../assets/img/product/Pic_PGL_D.png"
                    alt=""
                  />
                </div>
              </div>
              <div class="padding_40">
                <div class="title">课程特色</div>
                <p class="text_center">
                 PGL幼儿体育游戏库的特点分别是兼收并蓄、多元组合和订制教学。兼收并蓄是指体育游戏单元均符合《3-6岁儿童学习与发展指南》和《全
国足球特色幼儿园游戏活动指南》。多元组合是指老师可以按照不同类型、不同主题、不同顺序来进行游戏单元的组合，现阶段可组合出100
0+节幼儿体育课程。订制教学是指教师可以利用体育游戏单元编写适合本园所幼儿的体育游戏课程，也可根据测试和评估中出现的弱项进行
针对性补足。
                </p>
              </div>
              <div class="padding_40 display_box">
                <div>
                  <img
                    src="../../../../assets/img/product/Pic_PGL_JSBX.png"
                    alt=""
                    class="imageP"
                  />
                </div>
                <div>
                  <img
                    src="../../../../assets/img/product/Pic_PGL_DYZH.png"
                    alt=""
                    class="imageP"
                  />
                </div>
                <div>
                  <img
                    src="../../../../assets/img/product/Pic_PGL_DZJX.png"
                    alt=""
                    class="imageP"
                  />
                </div>
              </div>
              <div class="padding_40 line_minheight"></div>
            </div>
            <div class="" v-if="courseChosen === 3">
             <div class="lga_center phg_center">
                <div>
                  <h5 class="title">幼儿系列课程</h5>
                  <p class="text-center">
                    PHG幼儿体育家庭课程采用“6+1”主题，充分利用移动端，将幼
儿体育游戏课程适当拆分，形成可以在家或户外完成的课程。利用
体育游戏课程视频让更多的家长了解幼儿体育活动相关知识。
                  </p>
                  <p class="text-center">
                   PHG幼儿体育家庭课程共计<span>491</span>节课程，其中小班<span>176</span>节、中班 <span>160</span>节、大班<span>155</span>节，每节课程<span>4~8</span>分钟，可进行中英双语切换在进行体育游戏活动时提高语言能力。
                  </p>
                </div>
              </div>
              <div class="padding_40 display_box">
                <div class="dis_time_box">
                  <img
                    src="../../../../assets/img/product/Pic_PHG_A.png"
                    alt=""
                  />
                </div>
                <div class="dis_time_box">
                  <img
                    src="../../../../assets/img/product/Pic_PHG_B.png"
                    alt=""
                  />
                </div>
                <div class="dis_time_box">
                  <img
                    src="../../../../assets/img/product/Pic_PHG_C.png"
                    alt=""
                  />
                </div>
                <div class="dis_time_box">
                  <img
                    src="../../../../assets/img/product/Pic_PHG_D.png"
                    alt=""
                  />
                </div>
              </div>
              <div class="padding_40">
                <div class="title">课程特色</div>
                <p class="text_center">
                 PHG幼儿体育家庭课程的特点分别为操作简便、适用家庭和家园共育。操作简便是指利用手机登录Prefootball小程序，播放视频即可。操作
方便快捷，室内室外均可进行，增加家长与孩子的互动。适用家庭是指本课程更适合在较小空间、较低人数和较少器材的条件下完成，游戏化
的形式也可避免练习枯燥无味。家园共育是指将家长融入到幼儿体育教育当中，提高家长对体育活动的基础认知和知识储备，从而打通幼儿园
和家庭。
                </p>
              </div>
              <div class="padding_40 display_box">
                <div>
                  <img
                    src="../../../../assets/img/product/Pic_PHG_CZJB.png"
                    alt=""
                    class="imageP"
                  />
                </div>
                <div>
                  <img
                    src="../../../../assets/img/product/Pic_PHG_SYJT.png"
                    alt=""
                    class="imageP"
                  />
                </div>
                <div>
                  <img
                    src="../../../../assets/img/product/Pic_PHG_JYGY.png"
                    alt=""
                    class="imageP"
                  />
                </div>
              </div>
              <div class="padding_40 line_minheight"></div>
              <img src="../../../../assets/img/product/Pic_PHG_KCLB.png" alt="" class="Pic_PHG_KCLB">
            </div>
            <div class="" v-if="courseChosen === 4">
              <div class="lga_center aicenter">
                <div>
                  <h5 class="title">AI互动课堂</h5>
                  <p class="text-center">
                    AI互动课堂是在LGA课程基础上，在上课期间弹出AI游戏动作测试
挑战，教师可以选择是否挑战，参加挑战启动摄像头，利用AI科技，
丰富幼儿课堂互动趣味，同时也可以园园互动，园所之间实现线上
实时PK，展现园所实力。
                  </p>
                  <p class="text-center">
                  AI互动课堂游戏共<span>144</span>个游戏动作，其中小班<span>48</span>个、中班<span>48</span>个、 大班<span>48</span>个，每个游戏3秒钟，在进行AI互动游戏测试中提高幼儿兴趣。
                  </p>
                  <p>
                    <img src="../../../../assets/img/product/Pic_AI_JQQD.png" alt="" class="Pic_AI_JQQD">
                  </p>
                </div>
              </div>
              <div class="padding_40 display_box">
                <div class="dis_time_box">
                  <img
                    src="../../../../assets/img/product/Pic_AI_A.png"
                    alt=""
                  />
                </div>
                <div class="dis_time_box">
                  <img
                    src="../../../../assets/img/product/Pic_AI_B.png"
                    alt=""
                  />
                </div>
                <div class="dis_time_box">
                  <img
                    src="../../../../assets/img/product/Pic_AI_C.png"
                    alt=""
                  />
                </div>
                <div class="dis_time_box">
                  <img
                    src="../../../../assets/img/product/Pic_AI_D.png"
                    alt=""
                  />
                </div>
              </div>
              <div class="padding_40">
                <div class="title">课程特色</div>
                <p class="text_center">
                AI互动课堂的特点是利用AI智能识别动作，提高动作识别率，增加课堂趣味性，体现课堂科技感，增强园所之间互动性。
                </p>
              </div>
              <div class="padding_40 display_box">
                <div>
                  <img
                    src="../../../../assets/img/product/Pic_AI_HDDF.png"
                    alt=""
                    class="imageP"
                  />
                </div>
                <div>
                  <img
                    src="../../../../assets/img/product/Pic_AI_SSHD.png"
                    alt=""
                    class="imageP"
                  />
                </div>
                <div>
                  <img
                    src="../../../../assets/img/product/Pic_AI_YYHD.png"
                    alt=""
                    class="imageP"
                  />
                </div>
              </div>
              <div class="h40"></div>
            </div>
          </div>
          <el-tabs
            v-model="activeTabName"
            type="card"
            @tab-click="handleTabClick"
            v-if="courseChosen === 0"
          >
            <el-tab-pane label="备课教案及视频" name="first">
              <!-- 本学期未购课 -->
              <!-- <div class="blueState notState" v-if="sta === 1">
                <p>{{ courseBuyStatus.message }}</p>
              </div> -->
              <!-- 未上传转账凭证 -->
              <!-- <div class="blueState" v-if="sta === 8">
                <p>{{ courseBuyStatus.message }}</p>
              </div> -->
              <!-- 转账凭证审核中 -->
              <!-- <div class="blueState" v-if="sta === 2">
                <p>{{ courseBuyStatus.message }}</p>
                <button class="upload audit"></button>
              </div> -->
              <!-- 未上传合 -->
              <!-- <div class="blueState" v-if="sta === 3">
                <p>{{ courseBuyStatus.message }}</p>
                <button class="upload audit"></button>
              </div> -->
              <!-- 合同审核中 -->
              <!-- <div class="blueState" v-if="sta === 4">
                <p>{{ courseBuyStatus.message }}</p>
                <button class="upload audit"></button>
              </div> -->
              <!-- 已开课(去配课) -->
              <!-- <div class="blueState" v-if="sta === 5">
                <p>{{ courseBuyStatus.message }}</p>
              </div> -->
              <!-- 已开课(未配课ID0个) -->
              <!-- <div class="blueState" v-if="sta === 6">
                <p>{{ courseBuyStatus.message }}</p>
              </div> -->
              <!-- 您的课程还有多少时间到期 -->
              <!-- <div class="blueState timeState" v-if="sta === 7">
                <p>{{ courseBuyStatus.message }}</p>
              </div> -->
              <!-- <div> -->
              <!-- <payment-records @topFun="courseStatus"></payment-records>
                <contracts></contracts> -->
              <!-- </div> -->
              <div class="label" style="padding-top: 0">
                <!-- 备课教案及视频<br /> -->
                <span>全部课程（{{ watchNumber }}/{{ totalNumber }}）</span>
              </div>
              <!---课程列表购买---->
              <div class="btnStyle">
                <el-button
                  @click="lgalist(2)"
                  :class="{ active: gradeType === 2 }"
                  >小班</el-button
                >
                <el-button
                  @click="lgalist(1)"
                  :class="{ active: gradeType === 1 }"
                  >中班</el-button
                >
                <el-button @click="lgalist(0)" :class="{ active: !gradeType }"
                  >大班</el-button
                >
              </div>
              <div class="CourseTable">
                <el-table
                  border
                  :data="courseList"
                  style="width: 100%; border-radius: 10px; overflow: hidden"
                  :header-cell-style="{
                    background: '#BA3037',
                    color: 'white',
                    textAlign: 'center',
                  }"
                  :row-style="{
                    height: '40px',
                    overflow: 'hidden',
                    textAlign: 'center',
                  }"
                  class-name="elTable"
                  header-cell-class-name="message-header_row_style"
                  cell-class-name="message-row-style"
                >
                  <el-table-column
                    show-overflow-tooltip
                    width="130px"
                    :resizable="false"
                    prop=""
                    label="班级"
                  >
                    <template scope="scope">
                      {{ gradeTypeNames[scope.row.gradeType] }}班
                    </template>
                  </el-table-column>
                  <el-table-column
                    show-overflow-tooltip
                    :resizable="false"
                    label="章节"
                    width="130px"
                  >
                    <template scope="scope">
                      <span>第{{ scope.row.lessons }}节</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    :resizable="false"
                    show-overflow-tooltip
                    prop="videoname"
                    label="课程名称"
                  >
                  </el-table-column>
                  <el-table-column
                    :resizable="false"
                    show-overflow-tooltip
                    label="课程简介"
                  >
                    <template scope="scope">
                      <span v-html="scope.row.videoinfo"> </span>
                    </template>
                  </el-table-column>

                  <el-table-column
                    :resizable="false"
                    label="教案"
                    width="170px"
                  >
                    <template scope="scope">
                      <button
                        class="courIcon"
                        v-if="scope.row.attachment"
                        @click="
                          windowoOpentPre(scope.row.attachment, 24, scope.row)
                        "
                        :disabled="!scope.row.publish"
                      >
                        <i
                          :class="[
                            'pdfIcon',
                            { pdfIconGren: !scope.row.publish },
                          ]"
                        ></i>
                        <span>在线浏览</span>
                      </button>
                      <button
                        class="courIcon"
                        v-else
                        :disabled="!scope.row.publish"
                      >
                        <span>--</span>
                      </button>
                    </template>
                  </el-table-column>
                  <el-table-column
                    :resizable="false"
                    label="查看"
                    width="170px"
                  >
                    <template scope="scope">
                      <button
                        @click="toAttendPre(scope.row, 25)"
                        :disabled="!scope.row.publish"
                        class="courIcon"
                      >
                        <i
                          :class="[
                            'videoIcon',
                            { videoIconGren: !scope.row.publish },
                          ]"
                        ></i>
                        <span>{{
                          !scope.row.publish ? "未解锁" : "可查看"
                        }}</span>
                      </button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-tab-pane>
            <el-tab-pane label="课程配置" name="third">
              <class-config></class-config>
            </el-tab-pane>
          </el-tabs>
          <div v-if="courseChosen === 1" style="margin-top: 0.4rem;padding:0 40px 40px;">
            <div class="btnStyle">
              <el-button
                v-for="title in titleList"
                :class="{ active: title.index == showClass }"
                :key="`this-PgsCourseList-${title.index}`"
                @click="changeTeacher(title.index)"
              >
                {{ title.title }}
                <!--                {{title}}-->
              </el-button>
            </div>
            <div class="CourseTable">
              <el-table
                border
                :data="PgsCourseList[titleIndex]"
                style="width: 100%; border-radius: 10px; overflow: hidden"
                :header-cell-style="{
                  background: '#BA3037',
                  color: 'white',
                  textAlign: 'center',
                }"
                :row-style="{
                  height: '40px',
                  overflow: 'hidden',
                  textAlign: 'center',
                }"
                class-name="elTable"
                header-cell-class-name="message-header_row_style"
                cell-class-name="message-row-style"
              >
                <el-table-column
                  show-overflow-tooltip
                  :resizable="false"
                  label="章节"
                  width="130px"
                >
                  <template scope="scope">
                    <span>第{{ scope.row.lessons }}节</span>
                  </template>
                </el-table-column>
                <el-table-column
                  :resizable="false"
                  show-overflow-tooltip
                  prop="videoname"
                  label="课程名称"
                >
                </el-table-column>
                <el-table-column
                  :resizable="false"
                  show-overflow-tooltip
                  label="课程简介"
                >
                  <template scope="scope">
                    <div v-html="scope.row.videoinfo"></div>
                  </template>
                </el-table-column>
                <el-table-column :resizable="false" label="教案" width="170px">
                  <template scope="scope">
                    <button
                      v-if="scope.row.attachment"
                      class="courIcon"
                      @click="
                        windowoOpentPre(scope.row.attachment, 32, scope.row)
                      "
                      :disabled="!scope.row.publish"
                    >
                      <i
                        :class="[
                          'pdfIcon',
                          { pdfIconGren: !scope.row.publish },
                        ]"
                      ></i>
                      <span>在线浏览</span>
                    </button>
                    <button
                      class="courIcon"
                      v-else
                      :disabled="!scope.row.publish"
                    >
                      <span>--</span>
                    </button>
                  </template>
                </el-table-column>
                <el-table-column :resizable="false" label="查看" width="170px">
                  <template scope="scope">
                    <button
                      @click="toAttendPre(scope.row, 33)"
                      :disabled="!scope.row.publish"
                      class="courIcon"
                    >
                      <i
                        :class="[
                          'videoIcon',
                          { videoIconGren: !scope.row.publish },
                        ]"
                      ></i>
                      <span>{{
                        !scope.row.publish ? "未解锁" : "可查看"
                      }}</span>
                    </button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div v-if="courseChosen === 2" style="margin-top: 0.4rem;padding:0 40px 40px;">
            <div class="btnStyle">
              <el-button
                v-for="item in gradeList"
                :class="{ active: curGradeId === item.id }"
                :key="item.id"
                @click="changeGrade(item)"
                >{{ item.name }}</el-button
              >
              <!-- <el-button
                @click="lgalist(1)"
                :class="{ active: gradeType === 1 }"
                >中班</el-button
              >
              <el-button @click="lgalist(0)" :class="{ active: !gradeType }"
                >大班</el-button
              > -->
            </div>
            <div class="CourseTable">
              <el-table
                border
                :data="MpsCourseList"
                style="width: 100%; border-radius: 10px; overflow: hidden"
                :header-cell-style="{
                  background: '#BA3037',
                  color: 'white',
                  textAlign: 'center',
                }"
                :row-style="{
                  height: '40px',
                  overflow: 'hidden',
                  textAlign: 'center',
                }"
                class-name="elTable"
                header-cell-class-name="message-header_row_style"
                cell-class-name="message-row-style"
              >
                <el-table-column
                  show-overflow-tooltip
                  :resizable="false"
                  width="130px"
                  label="章节"
                >
                  <template scope="scope">
                    <span>第{{ scope.row.lessons }}节</span>
                  </template>
                </el-table-column>
                <el-table-column
                  :resizable="false"
                  show-overflow-tooltip
                  prop="videoname"
                  label="课程名称"
                >
                </el-table-column>
                <el-table-column
                  :resizable="false"
                  show-overflow-tooltip
                  label="课程简介"
                >
                  <template scope="scope">
                    <span v-html="scope.row.videoinfo"> </span>
                  </template>
                </el-table-column>
                <el-table-column :resizable="false" label="教案" width="170px">
                  <template scope="scope">
                    <button
                      v-if="scope.row.attachment"
                      class="courIcon"
                      @click="
                        windowoOpentPre(scope.row.attachment, 34, scope.row)
                      "
                      :disabled="!scope.row.publish"
                    >
                      <i
                        :class="[
                          'pdfIcon',
                          { pdfIconGren: !scope.row.publish },
                        ]"
                      ></i>
                      <span>在线浏览</span>
                    </button>
                    <button
                      class="courIcon"
                      v-else
                      :disabled="!scope.row.publish"
                    >
                      <span>--</span>
                    </button>
                  </template>
                </el-table-column>
                <el-table-column :resizable="false" label="查看" width="170px">
                  <template scope="scope">
                    <button
                      @click="toAttendPre(scope.row, 35)"
                      :disabled="!scope.row.publish"
                      class="courIcon"
                    >
                      <i
                        :class="[
                          'videoIcon',
                          { videoIconGren: !scope.row.publish },
                        ]"
                      ></i>
                      <span>{{
                        !scope.row.publish ? "未解锁" : "可查看"
                      }}</span>
                    </button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--课程购买弹窗-->
    <popupwindows
      ref="popupwindows"
      @close="close"
      v-if="dialogStatus"
    ></popupwindows>

    <!--上传凭证-->
    <!-- <upload-proof ref="uploadproof"></upload-proof> -->
  </div>
</template>

<script>
import Popupwindows from "./popUpWindows";
import UploadProof from "@/views/admin/school/classroom/uploadProof";
import ClassConfig from "@/views/admin/school/classConfig";
import Contracts from "@/views/admin/school/classroom/contracts";
import PaymentRecords from "@/views/admin/school/classroom/paymentRecords";
import videoList from "@/components/school/videoList.vue";

export default {
  name: "lgaClasses",
  components: {
    UploadProof,
    Popupwindows,
    ClassConfig,
    Contracts,
    PaymentRecords,
    videoList,
  },

  //LGA课程
  data() {
    return {
      riseUp: true,
      activeTabName: "first",
      courseChosen: 0,
      number: 1,
      courseId: 0,
      isPremium: 1,
      // 课程级别及名称
      gradeTypeTitles: {
        0: "大班课程",
        1: "中班课程",
        2: "小班课程",
      },

      // 课程级别对应的标签
      gradeTypeNames: {
        2: "小",
        1: "中",
        0: "大",
      },

      // 课程列表
      courseList: [],
      courseChosenList: "",
      // 弹窗是否可见
      dialogStatus: false,

      //上传凭证弹窗是否可见
      uploadStatus: false,

      orderStatus: false,

      courseBuyStatus: "",

      sta: "",
      PgsCourseList: [],
      videoList: "",
      MpsCourseList: [],
      gradeType: 2, // 默认选中2小班
      totalNumber: "",
      watchNumber: "",
      titleList: [],
      titleIndex: 0,
      showClass: 0,
      curUrl: "",
      gradeList: [
        {
          name:'小班',
          id:2
        },
        {
          name: "中班",
          id: 1,
        },
        {
          name:'大班',
          id:0
        },
      ],
      curGradeId:2,
    };
  },
  watch: {},
  methods: {
    windowoOpentPre(url, id, item) {
      this.curUrl = url;
      this.$store.dispatch("checkAuth", {
        id: id,
        fn: this.windowoOpent,
        that: this,
        params: item,
      });
    },
    windowoOpent(item) {
      // this.api.unit
      //   .getPlayInfoByToken({ videoId: item.id, definition: "" })
      //   .then((res) => {
      //     if (res.code == 20000) {
      //       window.location =
      //         "http://footballcn.oss-accelerate.aliyuncs.com" + item.attachment;
      //       if (item.publish === 0) {
      //         this.$notice({
      //           type: "success",
      //           message: "敬请期待",
      //         }).isShow();
      //         return;
      //       }
      //     } else {
      //       this.$notice({
      //         type: "success",
      //         message: res.message,
      //       }).isShow();
      //     }
      //   });

      // 公益园进入 点击课程查看是否登录>是否是公益园
      // 点击上课 体验课程无需登录
      // 付费课程需要登录
      if (!this.$store.state.userInfo.token) {
        this.$store.commit("setLoginState", "login");
        return;
      }
      if (!this.$store.state.userInfo.token && this.$route.query.welfare) {
        this.$store.commit("setLoginState", "login");
        return;
      }
      if (this.$store.state.userInfo.token) {
        this.api.park
          .invoiceType({ schoolId: this.$store.state.userInfo.schoolId })
          .then((res) => {
            // this.phone = res.data.mobile.slice(1);
            if (res.code) {
              if (res.data !== 5) {
                if (this.$route.query.welfare) {
                  this.$notice({
                    message:
                      "您不是公益幼儿园，请前往校园中心进行相关教学工作园用户使用",
                  }).isShow();
                  return;
                } else {
                  this.api.unit
                    .getPlayInfoByToken({ videoId: item.id, definition: "" })
                    .then((res) => {
                      if (res.code == 20000) {
                        this.tabVideo = item;
                        if (item.publish === 0) {
                          this.$notice({
                            type: "success",
                            message: "敬请期待",
                          }).isShow();
                          return;
                        }
                        window.location =
                          "http://footballcn.oss-accelerate.aliyuncs.com" +
                          item.attachment;
                      } else {
                        this.$notice({
                          type: "success",
                          message: res.message,
                        }).isShow();
                      }
                    });
                }
              } else {
                this.tabVideo = item;
                if (item.publish === 0) {
                  this.$notice({
                    type: "success",
                    message: "敬请期待",
                  }).isShow();
                  return;
                }
                window.location =
                  "http://footballcn.oss-accelerate.aliyuncs.com" +
                  item.attachment;
              }
            }
          });
        return;
      }
      // if (this.$route.query.courseId === "1") {
      //   this.$store.commit("setLoginState", "login");
      // }
      this.tabVideo = item;
      if (item.publish === 0) {
        this.$notice({
          type: "success",
          message: "敬请期待",
        }).isShow();
        return;
      }
      window.location =
        "http://footballcn.oss-accelerate.aliyuncs.com" + item.attachment;
    },

    courseChosenstatus(e = 0) {
      this.courseChosen = e;
      if (e === 0) {
        this.lgalist(2);
      } else if (e === 1) {
        console.log("11111");
        this.courseId = 39;
        this.onFindPaidCourseStatus();
      } else if (e === 2) {
        this.courseId = 40;
        this.onFindPaidCourseStatus();
      }
    },

    //是否购买LAG课程
    onFindPaidCourseStatus() {
      let that = this;
      // let data = {
      //   schoolId: this.$store.state.userInfo.schoolId,
      // };
      // this.api.school.findPaidCourseStatus(data).then((res) => {
      //   console.log("是否购买LAG课程", res.data);
      //   if (res.data === 1) {
      //     that.getPgsCourseList();
      //     that.getMpsCourseList();
      //   } else {
      //     this.$notice({
      //       message: "请购买套餐后进行播放",
      //     }).isShow();
      //   }
      // });
      that.getPgsCourseList();
      that.getMpsCourseList();
    },
    // submitFun() {
    //   console.log('没走吗?')
    //   this.courseStatus()
    // },
    //购买课程状态
    courseStatus() {
      let data = {
        schoolId: this.$store.state.userInfo.schoolId,
        userId: this.$store.state.userInfo.userId,
      };
      this.api.school.findOrderStatus(data).then((res) => {
        console.log("购买课程状态", res);
        this.courseBuyStatus = res;
        this.sta = res.data.status;
      });
    },

    //点击上传凭证submitList
    uploadShow(orderId = 0, orderType = "", numberStatus) {
      this.$refs.uploadproof.show(orderId, orderType, numberStatus);
    },

    //tab切换 课程列表，购买课程，课程配置
    handleTabClick(tab, event) {
      console.log("tabName", tab, event);
    },
    //班级总数

    //传班级类型和课程类型
    lgalist(val = undefined) {
      console.log("var1231313", typeof val);
      this.gradeType = val === undefined ? 2 : val;
      const self = this;
      self.getCourseList({
        courseType: self.courseChosen,
        gradeType: val,
      });

      self.getVideoFindVideoCountInfo({
        courseType: self.courseChosen,
        gradeType: val,
      });
    },

    getVideoFindVideoCountInfo(params = { courseType: "0", gradeType: "2" }) {
      const that = this;
      // console.log('参数类型',typeof params.gradeType)
      return this.api.course.videoFindVideoCountInfo(params).then((res) => {
        console.log("res总数", res);
        that.totalNumber = res.data.total;
        that.watchNumber = res.data.publishCount;
        return Promise.resolve(res.data);
      });
    },
    // LGA课程
    getCourseList(params = { courseType: "0", gradeType: "2" }) {
      const self = this;
      return this.api.course.findVideoInfo(params).then((res) => {
        console.log("res课程列表", res.data);
        // self.courseList[[params.gradeType]] = res.data
        self.courseList = res.data;
        // console.log('courseList',self.courseList[0].lessons)
        return Promise.resolve(res.data);
      });
    },

    //PGS课程
    getPgsCourseList() {
      let data = {
        courseId: this.courseId,
        isPremium: this.isPremium,
      };
      console.log("//PGS课程data", data);
      // this.tabClass = data.courseId;
      this.api.course.findCoursesOfTeacher_V2(data).then((res) => {
        //
        //
        // this.PgsCourseList = res.data;
        //教练列表
        this.titleList = [];
        res.data.map((item, i) => {
          console.log("PGS课程dataitem", item);
          this.titleList.push({ title: item.title, index: i });
          this.PgsCourseList.push(item.videoList);
        });
      });
    },
    changeTeacher(index) {
      console.log("idnex", index);
      this.titleIndex = index;
      this.showClass = index;
    },

    //MPS课程
    getMpsCourseList() {
      let data = {
        courseId: this.curGradeId == 0 ? 45 :this.curGradeId == 1 ? 46 : 47,
        isPremium: this.isPremium,
        gradeType: this.curGradeId,
      };
      console.log("//MPS课程", data);
      // this.tabClass = data.courseId;
      this.api.course.findCoursesOfTeacher(data).then((res) => {
        this.MpsCourseList = res.data;
      });
    },
    // showDialogPre(){
    //   this.$store.dispatch('checkAuth',{
    //     id:23,
    //     fn:this.showDialog,
    //     that:this,
    //   })
    // },
    //课程购买
    showDialog() {
      this.dialogStatus = true;
      if (this.$store.state.userInfo.teacherId) {
        this.dialogStatus = false;
        this.$notice({
          message: "请使用园长账号购买课程",
        }).isShow();
        return false;
      } else {
        this.$router.push({
          path: "/product",
          query: {
            type: "class",
          },
        });
      }
    },
    close() {
      this.dialogStatus = false;
    },
    toAttendPre(item, id) {
      this.$store.dispatch("checkAuth", {
        id: id,
        fn: this.toAttend,
        that: this,
        params: item,
      });
    },
    //观看视频
    toAttend(item) {
      // 公益园进入 点击课程查看是否登录>是否是公益园
      // 点击上课 体验课程无需登录
      // 付费课程需要登录
      if (!this.$store.state.userInfo.token) {
        this.$store.commit("setLoginState", "login");
        return;
      }
      if (!this.$store.state.userInfo.token && this.$route.query.welfare) {
        this.$store.commit("setLoginState", "login");
        return;
      }
      if (this.$store.state.userInfo.token) {
        this.api.park
          .invoiceType({ schoolId: this.$store.state.userInfo.schoolId })
          .then((res) => {
            // this.phone = res.data.mobile.slice(1);
            if (res.code) {
              if (res.data !== 5) {
                if (this.$route.query.welfare) {
                  this.$notice({
                    message:
                      "您不是公益幼儿园，请前往校园中心进行相关教学工作园用户使用",
                  }).isShow();
                  return;
                } else {
                  this.getNormaoInfo(item);
                }
              } else {
                this.tabVideo = item;
                if (item.publish === 0) {
                  this.$notice({
                    type: "success",
                    message: "敬请期待",
                  }).isShow();
                  return;
                }
                this.$router.push({
                  path: "/classVideo",
                  query: {
                    status: "1",
                    videoname: item.videoname,
                    id: item.id,
                    normalUrl: item.normalUrl,
                    videoinfo: item.videoinfo,
                    attachment: item.attachment || "",
                    welfare: "welfare",
                  },
                });
              }
            }
          });
        return;
      }
      // if (this.$route.query.courseId === "1") {
      //   this.$store.commit("setLoginState", "login");
      // }
      this.tabVideo = item;
      if (item.publish === 0) {
        this.$notice({
          type: "success",
          message: "敬请期待",
        }).isShow();
        return;
      }
      this.$router.push({
        path: "/classVideo",
        query: {
          statusName: "1",
          videoname: item.videoname,
          id: item.id,
          normalUrl: item.normalUrl,
          videoinfo: item.videoinfo,
          attachment: item.attachment || "",
          isFree: this.apiData.courseId === "2",
        },
      });
    },
    // 非公益园判断接口
    getNormaoInfo(item) {
      this.api.unit
        .getPlayInfoByToken({ videoId: item.id, definition: "" })
        .then((res) => {
          if (res.code == 20000) {
            this.tabVideo = item;
            if (item.publish === 0) {
              this.$notice({
                type: "success",
                message: "敬请期待",
              }).isShow();
              return;
            }
            this.$router.push({
              path: "/classVideo",
              query: {
                status: "1",
                type: item.type,
                videoname: item.videoname,
                id: item.id,
                normalUrl: item.normalUrl,
                videoinfo: item.videoinfo,
                attachment: item.attachment || "",
              },
            });
          } else {
            this.$notice({
              type: "success",
              message: res.message,
            }).isShow();
          }
        });
    }, //鉴权
    goClassPre() {
      this.$store.dispatch("checkAuth", {
        id: 22,
        fn: this.goClass,
        that: this,
      });
    },
    //一键上课
    goClass() {
      if (!this.$store.state.userInfo.teacherId) {
        this.$notice({
          message: "请使用教师账号一键上课",
        }).isShow();
        return;
      }
      let data = {
        schoolId: this.$store.state.userInfo.schoolId,
      };
      this.api.nAdmin.findPaidCourseStatus(data).then((res) => {
        if (res.flag) {
          //res.data 0 未购课  1已买课
          if (res.data) {
            this.$router.push({
              path: "/admin/classroom/onlineclass",
            });
          } else {
            this.$notice({
              message: res.message,
            }).isShow();
            return;
          }
        } else {
          this.$notice({
            message: res.message,
          }).isShow();
          return;
        }
      });
    },
    changeGrade(data) {
      this.curGradeId = data.id;
      // let data1 = {
      //   schoolId: this.$store.state.userInfo.schoolId,
      // };
      // this.api.school.findPaidCourseStatus(data1).then((res) => {
      //   if (res.data === 1) {
      //     this.getMpsCourseList();
      //   } else {
      //     this.$notice({
      //       message: "请购买套餐后进行播放",
      //     }).isShow();
      //   }
      // });
      this.getMpsCourseList();
    }
  },
  mounted() {
    this.courseStatus();
    this.lgalist(2);
  },
};
</script>

<style lang="scss" scoped>

.el-table--enable-row-transition .el-table__body td.el-table__cell {
  text-align: center;
}

.label {
  padding: 15px 0 18px 0;
  font-size: 24px;
  font-weight: bold;
  color: #ba3037;
  span {
    display: inline-block;
    font-size: 20px;
    // margin-top: 20px;
    font-weight: bold;
    color: #333333;
  }
}

.ClassTitle {
  cursor: pointer;
  .classText {
    float: left;
    h5 {
      font-size: 24px;
      font-weight: bold;
      color: #333333;
    }
    p {
      font-size: 20px;
      font-weight: 400;
      color: #333333;
      span {
        color: #fac000;
      }
    }
  }
  .upload {
    position: absolute;
    top: 10px;
    right: 0;
    width: 100px;
    height: 38px;
    background: linear-gradient(232deg, #b93037, #ef525b);
    box-shadow: 0 3px 6px 0 rgba(255, 67, 77, 0.33);
    border-radius: 6px;
    font-size: 18px;
    border: none;
    color: #fff;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
  .gray {
    top: 35px;
    height: 50px;
    line-height: 50px;
    border-radius: 50px;
    background: #ccc;
  }
}

.videoList {
  width: 100%;
  background: #fff;

  .videoCenter {
    width: 1434px;
    margin: 0 auto;
    padding: 36px 0 50px 0;
  }
}

.videoText {
  overflow: hidden;

  h4 {
    float: left;
    font-size: 36px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 40px;
  }

  span {
    float: right;
    width: 216px;
    height: 52px;
    line-height: 52px;
    background: #c5383f;
    box-shadow: 0 3px 20px 0 rgba(197, 56, 63, 0.39);
    border-radius: 26px;
    color: #fff;
    font-size: 28px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }

    a {
      color: #fff;
    }
  }
}

.blueState {
  width: 680px;
  height: 60px;
  //background: url(../../../../assets/img/online/Pic_Bg_Blue.png) no-repeat;
  //background-size: 100% 100%;
  box-sizing: border-box;
  position: relative;

  p {
    width: 450px;
    overflow: hidden;
    font-size: 20px;
    font-weight: 500;
    color: #333;
    line-height: 60px;
  }

  button {
    position: absolute;
    border: none;
    top: 30px;
    right: 30px;
    width: 136px;
    height: 68px;
    line-height: 68px;
    text-align: center;
    background: url(../../../../assets/img/online/Btn_Bg_W.png) no-repeat;
    background-size: 100% 100%;
    font-size: 18px;
    font-weight: 400;
    color: #374d86;
    cursor: pointer;
  }

  .audit {
    width: 91px;
    height: 85px;
    top: 18px;
    background: url(../../../../assets/img/online/Icon_Zt_Ing.png) no-repeat;
    background-size: 100% 100%;
  }

  .buyClass {
    color: #fff;
    background: url(../../../../assets/img/online/Btn_Bg_Red.png) no-repeat;
    background-size: 100% 100%;
  }
}

.courseAll {
  width: 1434px;
  margin: 0 auto;
}

.course-fun {
  overflow: hidden;
  display: flex;
  justify-content: space-around;
  .active_box {
    .courseNav {
      width: 288px;
      height: 131px;

      box-sizing: border-box;
      cursor: pointer;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      background: url(../../../../assets/img/product/Btn_class_Bg_Unc.png)
        no-repeat;
      background-size: 100% 100%;
      span {
        font-size: 24px;
        font-weight: bold;
        color: #ffffff;

        display: block;
        margin-bottom: 11px;
      }

      p {
        font-size: 24px;
        font-weight: bold;
        color: #ffffff;
      }
    }
  }
  .active_box:hover {
    background-image: -webkit-linear-gradient(
      -90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 1) 100%
    );
    .courseNav {
      background: url(../../../../assets/img/product/Btn_Bg_Class_C.png)
        no-repeat;
      background-size: 100% 100%;
    }
  }
  .bg_box {
    width: 288px;
    height: 131px;
    background-image: -webkit-linear-gradient(
      -90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 1) 100%
    );

    .activePic {
      background: url(../../../../assets/img/product/Btn_Bg_Class_C.png)
        no-repeat;
      background-size: 100% 100%;
    }
  }
}

.courseCenter {
  background: #fff;
}

.coursText {
  .detailsText {
    @include flex(row, space-between, flex-start);
    h5 {
      font-size: 24px;
      font-weight: bold;
      color: #333333;
      margin-bottom: 18px;
    }

    p {
      font-size: 18px;
      font-weight: 400;
      color: #333333;
      line-height: 28px;
    }
    img {
      width: 204px;
      height: 202px;
    }
  }
}

/deep/ .el-tabs__content {
  overflow: inherit;
  padding: 0 40px 40px;
}

.activeTab {
  padding: 30px 30px 15px 30px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  overflow: hidden;
}

/deep/ .el-tabs--card > .el-tabs__header {
  margin: 40px;
  height: 42px;
  line-height: 42px;
  width: 315px;
  padding-left: 10px;
  // padding-right: 10px;
  background: #ffffff;
  box-shadow: 0 2px 12px 0 rgba(189, 189, 189, 0.41);
  border-radius: 2px;
  font-size: 20px;
  font-weight: 400;
  color: #374d86;
}

/deep/ .el-tabs__nav-wrap {
  overflow: inherit;
}

/deep/ .el-tabs__nav-scroll {
  overflow: inherit;
}

/deep/ .el-tabs--card > .el-tabs__header .el-tabs__nav {
  border: none !important;
}

/deep/ .el-tabs--card > .el-tabs__header .el-tabs__item {
  border: none !important;
  position: relative;
  font-size: 20px;
  color: #374d86;
}

/deep/ .is-active {
  height: 48px;
  line-height: 48px;
  background: linear-gradient(232deg, #364b82, #4c66ad);
  border-radius: 6px;
  color: #fff !important;
  margin-bottom: 4px;
  position: absolute;
  top: -3px;
  left: 0;
}

.courseTitle {
  overflow: hidden;

  .lagTitle {
    font-size: 0.36rem;
    font-weight: bold;
    color: #333333;
  }

  h6 {
    color: #001827;
    font-size: 24px;
    font-weight: bold;

    line-height: 100px;
    height: 100px;
  }

  .miniClass {
    width: 80px;
  }
  .btnStyle {
    margin-bottom: 20px;
    button {
      padding: 10px 22px;
      border: none;
      border-radius: 6px;
      color: #ba3037;
      margin-right: 15px;
      font-size: 18px;
      border: 1px solid #ba3037;
    }
    .active {
      background: linear-gradient(232deg, #b93037, #ef525b);
      box-shadow: 0 3px 6px 0 rgba(255, 67, 77, 0.33);
      color: #fff;
      border: 1px solid #ba3037;
    }
  }

  .courseBtn {
    width: 100px;
    height: 38px;
    line-height: 38px;
    background: linear-gradient(232deg, #b93037, #ef525b);
    box-shadow: 0 3px 6px 0 rgba(255, 67, 77, 0.33);
    border: none;
    border-radius: 6px;
    color: #fff;
    margin-left: 44px;
    a {
      color: #fff;
    }
  }
}
.courIcon {
  background: none;
  border: none;
  color: #ba3037;
  cursor: pointer;
  &:disabled {
    color: #969696;
  }
  .videoIcon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: url(../../../../assets/img/classHome/Icon_Class_Play.png)
      no-repeat top;
    background-size: 100% 100%;
    vertical-align: middle;
    margin-right: 8px;
  }
  .videoIconGren {
    background: url(../../../../assets/img/classHome/Icon_Class_UnabPlay.png)
      no-repeat top;
    background-size: 100% 100%;
  }
  .pdfIcon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: url(../../../../assets/img/classHome/Icon_Download_PDF.png)
      no-repeat top;
    background-size: 100% 100%;
    vertical-align: middle;
    margin-right: 8px;
  }
  .pdfIconGren {
    background: url(../../../../assets/img/classHome/Icon_Download_Unable.png)
      no-repeat top;
    background-size: 100% 100%;
  }

  span {
    vertical-align: middle;
    margin-left: 5px;
  }
}

.courseBox {
  //padding: 30px 30px 15px 30px;
  //box-shadow: 0 2px 12px 0 rgba(189, 189, 189, 0.41);
  //border-radius: 2px;
  //border:1px solid #f1f1f1;
  h4 {
    margin-bottom: 15px;
    font-weight: bold;
    font-size: 20px;
  }

  .courseList {
    overflow: hidden;
    margin-left: -15px;
    display: flex;
    color: #fff;
    flex-wrap: wrap;

    li {
      padding: 15px 40px 15px 20px;
      border-radius: 4px;
      position: relative;
      width: 148px;
      text-align: center;
      margin-left: 15px;
      margin-bottom: 15px;
      cursor: pointer;
      box-sizing: border-box;
      background: linear-gradient(
        232deg,
        rgba(255, 24, 36, 0.75),
        rgba(255, 131, 137, 0.75)
      );
      box-shadow: 0 4px 10px 0 rgba(255, 88, 97, 0.24);

      &:hover {
        background-image: linear-gradient(#ffb2b6, #ff8c92);
      }

      h6 {
        font-size: 24px;
        font-weight: bold;
        color: #fff;
      }

      p {
        font-size: 18px;
        font-weight: 400;
        color: #ffffff;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .courseIcon {
        width: 62px;
        height: 42px;
        position: absolute;
        top: -3px;
        right: -3px;
        font-style: normal;
        padding-left: 8px;
        padding-top: 6px;
        font-size: 14px;
        font-weight: bold;
        box-sizing: border-box;
        //padding:2px 5px;
      }

      .small {
        color: #ff676f;
        background: url(../../../../assets/img/online/Icon_Bq_Class_XB.png)
          no-repeat;
        background-size: 100% 100%;
      }

      .centre {
        color: #fff;
        background: url(../../../../assets/img/online/Icon_Bq_Class_ZB.png)
          no-repeat;
        background-size: 100% 100%;
      }

      .big {
        color: #fff;
        background: url(../../../../assets/img/online/Icon_Bq_Class_DB.png)
          no-repeat;
        background-size: 100% 100%;
      }
    }

    .locked {
      background: linear-gradient(
          232deg,
          rgba(101, 101, 101, 0.75),
          rgba(195, 193, 194, 0.75)
        ),
        url(../../../../assets/img/online/Icon_class_Lock.png) no-repeat center
          center;

      background-size: contain;

      box-shadow: 0 4px 10px 0 rgba(98, 98, 98, 0.24);

      .backPic {
        width: 100%;
        height: 100%;
        margin: 0;
        background: url(../../../../assets/img/online/Icon_class_Lock.png)
          no-repeat center center;
        background-size: 70px 80px;
      }

      &:hover {
        background: grey;
      }
    }
  }
}

.CourseTable {
  box-shadow: 0 2px 12px 0 rgba(189, 189, 189, 0.41);
  border-radius: 10px;
  overflow: hidden;
 
}
/deep/ .el-table--border td,
.el-table--border th,
.el-table__body-wrapper .el-table--border.is-scrolling-left ~ .el-table__fixed {
  border-right: none;
}
/deep/.el-table--border .el-table__cell,
.el-table__body-wrapper .el-table--border.is-scrolling-left ~ .el-table__fixed {
  border-right: none;
  text-align: center;
}
.dis_flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .upload {
    width: 216px;
    height: 52px;
    line-height: 52px;
    background: #c5383f;
    box-shadow: 0 3px 20px 0 rgba(197, 56, 63, 0.39);
    border-radius: 26px;
    color: #fff;
    font-size: 28px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
  }
}
.lga_center {
  width: 100%;
  height: 507px;
  background: url("../../../../assets/img/product/Pic_GCZX_Bg_LGA.png")
    no-repeat left top;
  background-size: cover;
  justify-content: start;
  align-items: center;
  display: flex;
  div {
    padding-left: 102px;
    width: 560px;
    .title {
      font-size: 40px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
      margin-bottom: 20px;
      line-height: 40px;
    }
    .text-center {
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #dddddd;
      line-height: 32px;
      margin-bottom: 10px;
      span {
        color: #ffca6f;
        font-size: 24px;
      }
    }
  }
}
.PGS_center{
   background: url("../../../../assets/img/product/Pic_KCZX_BG_PGS.png")
    no-repeat left top;
    background-size:cover;
}
.pgl_center{
 background: url("../../../../assets/img/product/Pic_KCZX_Bg_PGL.png")
    no-repeat left top;
    background-size:cover;
}
.phg_center{
background: url("../../../../assets/img/product/Pic_KCZX_BG_PHG.png") no-repeat left top;
background-size:cover;
}
.aicenter{
background: url("../../../../assets/img/product/Pic_KCZX_Bg_AIKT.png") no-repeat left top;
background-size:cover;
}
.padding_40 {
  padding: 0 102px 0;
  .title {
    font-size: 32px;
    color: #333333;
    text-align: center;
    line-height: 32px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    margin-bottom: 26px;
  }
  .text_center {
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 30px;
    margin-bottom: 63px;
  }
}
.display_box {
  display: flex;
  justify-content: space-between;
  padding-bottom: 50px;
}
.dis_time_box {
  width: 292px;
  height: 166px;
  border-radius: 10px;
  background: pink;
  margin-top: -50px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
  }
}
.imageP {
  width: 250px;
  height: 285px;
}
.line_minheight {
  margin: 0 41px 0;

  border: 1px dashed #dddddd;
}
.Pic_AI_JQQD{
  width:108px;
  height:24px;
}
.Pic_PHG_KCLB{
width:100%;
height:auto;
margin:20px;
margin-top:40px;}
</style>
<style lang="scss">
.message-header_row_style {
  background: #ba3037 !important;
  color: #ffffff;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
  opacity: 0.9;
}
.message-row-style {
  border-bottom: 1px solid #dddddd !important;
}
</style>
