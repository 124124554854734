<template>
  <div class="attron">
    <el-dialog
        :visible.sync="visible"
        center>
      <template slot="title">
        <div class="upWT">添加发票</div>
        <div class="close">
        </div>
      </template>
      <el-form ref="form" :model="form" :rules="rules" class="demo-form-inline" label-width="1.4rem">
        <el-form-item label="单位名称" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="单位地址">
            <el-input v-model="form.address"></el-input>
        </el-form-item>
        <el-form-item label="单位开户行名称">
          <el-input v-model="form.bank"></el-input>
        </el-form-item>
        <el-form-item label="开票金额" prop="accountNumber">
          <el-input v-model="form.accountNumber"></el-input>
        </el-form-item>
        <el-form-item label="汇款时间">
          <el-date-picker type="date" placeholder="选择日期" v-model="form.time"></el-date-picker>
        </el-form-item>
        <el-form-item label="单位税号" prop="paragraph">
          <el-input v-model="form.paragraph"></el-input>
        </el-form-item>
        <el-form-item label="电话">
          <el-input v-model="form.phone"></el-input>
        </el-form-item>
        <el-form-item label="账号">
          <el-input v-model="form.account"></el-input>
        </el-form-item>
        <el-form-item label="汇款账户">
          <el-input v-model="form.remittance"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="emali">
          <el-input v-model="form.emali"></el-input>
        </el-form-item>
        <div class="hintMailbox">系统默认向所填电子邮箱发送电子发票, 如需纸质发票请联系客服</div>
        <el-form-item>
          <el-button type="primary" @click="submit('form')" class="btn active">提交</el-button>
          <el-button class="btn" @click="close">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "addInvoice",
  props: {
    id: String,
    default() {
      return {}
    }
  },
  watch: {},
  isActive: true,
  data() {
    const self = this

    return {
      visible: false,
      number: 0,
      // 弹窗状态  新增/编辑/查看
      alertStatus: '',
      form: {
        name: '',
        paragraph: '',
        address: '',
        phone: '',
        bank: '',
        account: '',
        accountNumber: '',
        remittance: '',
        time: '',
        emali: '',
      },
      rules: {
        name: [
          {
            required: true,
            trigger: 'blur',
            validator(rule, value, callback) {
              self.common.validateLength(value, 50)
                  ? callback()
                  : callback(new Error("请输入正确单位名称"))
            },
          }
        ],
        accountNumber: [
          {
            required: true,
            trigger: 'blur',
            validator(rule, value, callback) {
              self.common.integerSevenDecimalTwoNoContainsZero(value)
                  ? callback()
                  : callback(new Error("请输入正确开票金额"));
            }
          }
        ],
        paragraph: [
          {
            required: true,
            trigger: 'blur',
            validator(rule, value, callback) {
              self.common.validateChinese(value)
                  ? callback(new Error("请输入正确单位税号"))
                  : callback()
            }
          }
        ],
        emali: [
          {
            required: true,
            trigger: 'blur',
            validator(rule, value, callback) {
              if (!self.common.validateLength(value, 25)) {
                callback(new Error("请输入正确邮箱"));
              } else {
                self.common.isEmail(value) ? callback() : callback(new Error("请输入正确邮箱"))
              }
            }
          }
        ]
      },
    };
  },
  methods: {

    submit() {
      const self = this

      this.$refs.form.validate(function (valid) {
        if (!valid) {
          return false
        }
        const data = {
          ...self.form,
          orderId : self.id,
          schoolId: self.$store.state.userInfo.schoolId,
          userId: self.$store.state.userInfo.userId
        }

        self.api.admin.addInvoice(data).then(res => {
          if (res.flag) {
            self.$notice({type: 'success', message: res.message}).isShow()
            self.$emit('successData',true)
            self.visible = false
          } else {
            self.$notice({message: res.message}).isShow()
          }
        })

      })
    },

    tabClass: function (index) {
      this.number = index;
    },

    popup() {
      this.visible = true
    },

    show() {
      this.visible = true
    },
    verify() {
      this.visible = false;
      this.$emit('verify', this.visible)
    },
    close() {
      this.visible = false;
      this.$emit('verify', this.visible)
    }
  }
}
</script>

<style lang="scss" scoped>
.hintMailbox {
  font-size: 14px;
  font-weight: 400;
  color: #CB1B32;
  text-align: center;
}

.btn {
  width: 100px;
  height: 38px;
  //background: none;
  //border:none;
  border: 1px solid #BA3037;
  border-radius: 6px;
  background: #fff;
  color: #BA3037;
  font-size: 18px;
  margin: 0 25px;
}

.active {
  background: linear-gradient(232deg, #B93037, #EF525B);
  box-shadow: 0 3px 6px 0 rgba(255, 67, 77, 0.33);
  color: #fff;
  border: 1px solid transparent;
}


/deep/ .el-dialog--center {
  width: 610px;

  .el-form-item {
    margin-bottom: 20px;
  }
}

/deep/ .el-form-item__label {
  color: #333;
  font-size: 16px;
  line-height: 45px;
  margin-right: 14px;
}

/deep/ .el-input {
  width: 400px;
  display: inline;
}

/deep/ .el-input--suffix {
  width: 140px;
  color: #333;

}

/deep/ .el-input__inner {
  width: 400px;
  border: 1px solid #ddd;
  border-radius: 10px;
}

/deep/ .el-dialog__header {
  padding: 0;
  position: relative;
}

/deep/ .el-dialog__headerbtn {
  top: 0.15rem;
  opacity: 0;
}

.upWT {
  height: 50px;
  line-height: 50px;
  background: #B93138;
  color: #fff;
}

.close {
  position: absolute;
  top: 16px;
  right: 20px;
  width: 15px;
  height: 15px;
  background: url(../../../../assets/img/course/Icon_tc_close.png) no-repeat;
  background-size: cover;
}

/deep/ .el-input__suffix {
  line-height: .5rem;
}
</style>