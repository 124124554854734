<template>
  <div class="attron">
    <el-dialog :visible.sync="visible" center @close='closeDialog'>
      <template slot="title">
        <div class="upWT">{{numberStatus == 1 ? '上传凭证': '查看凭证'}}</div>
        <div class="close">
        </div>
      </template>
      <div class="uploadPic" >
        <div  style="display:inline-block; vertical-align: middle;" v-if="numberStatus == 1 ">
          <el-upload
              :action="constant.UPLOAD_URL + '/file/upload'"
              list-type="picture-card"
              :auto-upload="true"
              :on-success="onSchoolBusinessUploaded"
              :headers="{ token: $store.state.userInfo.token }"
              :file-list="imageUrlData"
              :limit="3"
              :on-exceed="schoolbusinessExceed" >
            <i class="el-icon-plus" style="line-height: normal"></i>
          </el-upload>
          <!-- <el-dialog  class="licensePic">

            < img width="100%" :src="constant.URL + i"  v-for="(i ,index) in intefile" :key="index" alt="" style="width: 64px; height: 64px">
          </el-dialog> -->
        </div>
        <div class="seePic" style="display:inline-block; vertical-align: middle;"  v-else>
          <img :src="constant.URL + i" alt="" v-for="(i,index) in intefile" :key="index" style="width: 64px; height: 64px;margin:0  10px">
        </div>
        <p>支付凭证</p >
      </div>

      <!-- <div class="orderList">
        <p><span>订单类型</span><em>{{ getOrderTypeName(order.ordertype) }}</em></p >
        <p><span>订单号</span><em>{{ order.orderid }}</em></p >
        <p><span>学员人数</span><em>{{ order.number }}</em></p >
        <p><span>订单金额</span><em>{{ order.price }}</em></p >
        <p><span>学期</span><em>{{ order.semesterIds }}</em></p >
      </div> -->
      <el-button type="primary" @click="submit" class="btn active" v-if="numberStatus == 1">{{imageUrlData.length>0?'正在审核':'提交'}}</el-button>
      <el-button class="btn" @click="cancel">{{numberStatus == 1 ? '取消': '确定'}}</el-button>
    </el-dialog>

  </div>
</template>

<script>
export default {
  name: "uploadProof",
  props: {
    statusnumber: String,
    numberStatus:Number,
    orderId:String,
    proofUrl:Array,
    default() {
      return {}
    }
  },
  data() {
    return {
      visible: true,
      dialogVisible:false,
      imageUrl: '',
      intefile: [],
      prevOrderId: 0,
      uploadedProof: [],
      schoolbusinessList:[], //上传凭证
     
      imageUrlData:[],
      //订单数据
      order: {
        id: 0, // 订单ID
        orderid: '', // 订单编号
        ordertype: '', // 订单类型
        number: 0, // 学员人数
        price: 0, // 订单金额
        semesterIds: '', // 学期：字符串

      }
    };
  },
  methods: {

    

   


    //上传凭证限制
    schoolbusinessExceed(){
      this.$notice({
        message:'只能上传三张图片'
      }).isShow()
    },
    //成功上传图片
    onSchoolBusinessUploaded(res) {
      this.intefile.push(res.data.filename)

    },
    
    hide() {
      this.visible = false
    },

    submit() {
      const self = this
      let intefile = self.intefile.join(',')
      const data = {
        orderId: Number(this.orderId),
        proofUlr: intefile,
      }
      if (self.intefile == '') {
        this.$notice({message: '请先上传凭证'}).isShow()
        return
      }
      this.api.product.uploadProof(data).then(res => {
        if (res.flag) {
          this.$notice({type: 'success', message: '支付凭证上传成功'}).isShow()
          this.closeDialog()
        } else {
          self.$notice({message: res.message}).isShow()
        }
      })
    },

    cancel() {
      this.visible = false
      this.$emit('cancel', this.order.id)
    },
    closeDialog(){
      console.log('guanbi')
       this.$emit('costListNesFun')
    }

  },
  mounted() {
    console.log(this.proofUrl)
    if(this.proofUrl){
this.intefile = this.proofUrl
    }
    
  }
}
</script>

<style lang="scss" scoped>


/deep/ .el-dialog__body {
  text-align: center;
}

.uploadPic {
  text-align: center;
 
  padding-bottom: 20px;

  p {
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    margin-top: 13px;
  }
}

.orderList {
  padding: 30px;

  p {
    margin-bottom: 28px;
    font-size: 16px;
    font-weight: 400;
    overflow: hidden;

    span {
      float: left;
      color: #888888;
    }

    em {
      float: right;
      color: #333333;
    }
  }
}


/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

/deep/ .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

/deep/ .avatar {
  width: 178px;
  height: 178px;
  display: block;
}


.hintMailbox {
  font-size: 14px;
  font-weight: 400;
  color: #CB1B32;
  text-align: center;
}

.btn {
  width: 100px;
  height: 38px;
  //background: none;
  //border:none;
  border: 1px solid #BA3037;
  border-radius: 6px;
  background: #fff;
  color: #BA3037;
  font-size: 18px;
  margin: 0 25px;
}

.active {
  background: linear-gradient(232deg, #B93037, #EF525B);
  box-shadow: 0 3px 6px 0 rgba(255, 67, 77, 0.33);
  color: #fff;
  border: 1px solid transparent;
}

/deep/ .el-input__inner {
  width: 400px;
  border: 1px solid #ddd;
  border-radius: 10px;
}

/deep/ .el-dialog__header {
  padding: 0;
  position: relative;
}

/deep/ .el-dialog__headerbtn {
  top: 0.15rem;
  opacity: 0;
}

.upWT {
  height: 50px;
  line-height: 50px;
  background: #B93138;
  color: #fff;
}

.close {
  position: absolute;
  top: 16px;
  right: 20px;
  width: 15px;
  height: 15px;
  background: url(../../../../assets/img/course/Icon_tc_close.png) no-repeat;
  background-size: cover;
}

/deep/ .el-input__suffix {
  line-height: .5rem;
}
</style>