<template>
  <div class="attron">
    <el-dialog
        :visible.sync="visible"
        @close="close"
        center>
      <template slot="title" >
        <div class="upWT">查看发票</div>
        <div class="close">
        </div>
      </template>
      <el-form ref="form" :model="form" class="demo-form-inline" label-width="1.4rem">
        <el-form-item label="单位名称:">
          {{form.name?form.name:'暂无'}}
        </el-form-item>
        <el-form-item label="请输入单位地址:">
          {{form.address?form.address:'暂无'}}
        </el-form-item >
        <el-form-item label="单位开户行名称:">
          {{form.bank?form.bank:'暂无'}}
        </el-form-item>
        <el-form-item label="开票金额:">
          {{form.accountNumber?form.accountNumber:'暂无'}}
        </el-form-item>
        <el-form-item label="汇款时间:">
          {{form.time?form.time:'暂无'}}
        </el-form-item>
        <el-form-item label="单位税号:" >
          {{form.paragraph?form.paragraph:'暂无'}}
        </el-form-item>
        <el-form-item label="电话:">
          {{form.phone?form.phone:'暂无'}}
        </el-form-item>
        <el-form-item label="账号:">
          {{form.account?form.account:'暂无'}}
        </el-form-item>
        <el-form-item label="汇款账户:">
          {{form.remittance?form.remittance:'暂无'}}
        </el-form-item>
        <el-form-item label="邮箱:">
          {{form.emali?form.emali:'暂无'}}
        </el-form-item>
        <el-form-item style="text-align:center;margin: 0 auto">
          <el-button class="btn" @click="close">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "seeInvoice",
  props: {
    id: String,
    statusnumber:Number,
    default() {
      return {}
    }

  },
  watch: {},
  data() {
    return {
      visible: true,
      number: 0,      // 弹窗状态  新增/编辑/查看
      alertStatus: '',
      form: {
        name: '',
        paragraph: '',
        address: '',
        phone: '',
        bank: '',
        account: '',
        accountNumber: '',
        remittance: '',
        time: '',
        emali: '',
      },
    };
  },
  methods: {
    //查看发票数据
    invoiceSee(){
      let that = this
      let data ={
        orderId: this.id,
      }
      this.api.admin.findByOrderId(data).then(res =>{
        that.form = res.data
      })
    },
    verify() {
      this.visible = false;
      this.$emit('verify', this.visible)
    },
    close() {
      this.visible = false;
      this.$emit('close', this.visible)
    }

  },
  mounted() {
    this.invoiceSee();
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-form-item__content{
  margin-left: 0 !important;
}


.hintMailbox {
  font-size: 14px;
  font-weight: 400;
  color: #CB1B32;
  text-align: center;
}

.btn {
  width: 100px;
  height: 38px;
  //background: none;
  //border:none;
  border: 1px solid #BA3037;
  border-radius: 6px;
  background: #fff;
  color: #BA3037;
  font-size: 18px;
  margin: 0 25px;
}

.active {
  background: linear-gradient(232deg, #B93037, #EF525B);
  box-shadow: 0 3px 6px 0 rgba(255, 67, 77, 0.33);
  color: #fff;
  border: 1px solid transparent;
}


/deep/ .el-dialog--center {
  width: 610px;

  .el-form-item {
    margin-bottom: 20px;
  }
}

/deep/ .el-form-item__label {
  color: #333;
  font-size: 16px;
  line-height: 45px;
  margin-right: 14px;
  width: auto !important;
}

/deep/ .el-input {
  width: 400px;
  display: inline;
}

/deep/ .el-input--suffix {
  width: 140px;
  color: #333;

}

/deep/ .el-input__inner {
  width: 400px;
  border: 1px solid #ddd;
  border-radius: 10px;
}

/deep/ .el-dialog__header {
  padding: 0;
  position: relative;
}

/deep/ .el-dialog__headerbtn {
  top: 0.15rem;
  opacity: 0;
}

.upWT {
  height: 50px;
  line-height: 50px;
  background: #B93138;
  color: #fff;
}

.close {
  position: absolute;
  top: 16px;
  right: 20px;
  width: 15px;
  height: 15px;
  background: url(../../../../assets/img/course/Icon_tc_close.png) no-repeat;
  background-size: cover;
}

/deep/ .el-input__suffix {
  line-height: .5rem;
}
</style>