<template>
  <div class="upWindows">
    <div v-if="visible">
      <el-dialog
        center
        :visible.sync="centerDialogVisible"
        @close="close"
        :close-on-click-modal="false"
      >
        <template slot="title">
          <div class="upWT">课程购买</div>
          <div class="close"></div>
        </template>
        <el-form :model="form" class="demo-form-inline">
          <el-form-item label="学期" :label-width="formLabelWidth">
            <el-row>
              <el-button
                size="small"
                round
                v-for="(v, i) in semesterList"
                :key="`buttonList-v-${i}`"
                class="btnClass"
                @click="changeSemester(v.id, v.price)"
                :class="{
                  semesterActive: form.semester.includes(v.id),
                  semDisabled: v.payStatus,
                }"
              >
                {{ v.name }} {{ v.payStatus ? "已买" : "" }}
              </el-button>
            </el-row>
          </el-form-item>
          <el-form-item label="幼儿人数" :label-width="formLabelWidth">
            <el-input-number v-model="form.number" type="number" :min="30">
              <span slot="suffix">人</span>
            </el-input-number>
          </el-form-item>
          <el-form-item label="支付方式" :label-width="formLabelWidth">
            <button
              type="button"
              class="btnClassWidth"
              v-for="(pw, i) in payWayList"
              :key="`item-item-${i}`"
              @click="changePayWat(pw.value)"
              :class="{ active: form.payWay === pw.value }"
            >
              {{ pw.label }}
            </button>
            <span class="moneyTotal">
              费用合计￥
              <em class="money">
                {{ form.price }}
              </em>
            </span>
            <ul class="bank" v-show="form.payWay === '在线支付'">
              <li>
                <label>开户名：</label>
                <span>小牛启航体育（北京）有限公司</span>
              </li>
              <li>
                <label>银行账号：</label>
                <span>0200048519200831908</span>
              </li>
              <li>
                <label>开户行：</label>
                <span>中国工商银行北京复外支行</span>
              </li>
            </ul>
          </el-form-item>
          <button type="button" class="purchaseBtn" @click="buyNow">
            立即购买课程·解锁更多权益
          </button>
        </el-form>
      </el-dialog>
    </div>

    <!--合同弹窗-->
    <contract-pop
      ref="contractpop"
      v-if="contractpop"
      :contracttext.sync="form"
      @close="close"
    ></contract-pop>
  </div>
</template>

<script>
import ContractPop from "@/views/admin/school/classroom/contractPop";

export default {
  name: "popupwindows",
  components: {
    ContractPop,
  },
  props: {},
  watch: {
    "form.semester": function () {
      this.refreshTotalPrice();
    },
    "form.number": function () {
      this.refreshTotalPrice();
    },
  },
  computed: {},
  data() {
    return {
      formLabelWidth: "90px", // 标签宽度
      visible: true, // 弹窗是否显示
      centerDialogVisible: true,
      contract: {}, // 合同数据
      contracttext: "",

      // 学期列表
      semesterList: [],

      // 学期名字对应价格
      semesterPrice: {},

      // 支付方式列表
      payWayList: [
        { value: "1", label: "微信支付" },
        { value: "0", label: "线下支付" },
      ],

      // 表单
      form: {
        semester: [], // 选中的学期的id
        name: 0, // 学期ID
        price: "", // 总价：选中的所有学期单价之和 乘 人数
        number: "", // 学员人数
        payWay: "", // 支付方式名称
      },
      contractpop: false,
    };
  },
  //方法
  methods: {
    refreshTotalPrice() {
      const self = this;
      this.form.price = 0;

      this.form.semester.forEach((id) => {
        self.form.price += parseFloat(self.semesterPrice[id]); //拿到 name 对应的这个值
      });

      this.form.price = (this.form.price * this.form.number).toFixed(2);
    },

    getSemesterList() {
      let that = this;
      let data = {
        schoolId: this.$store.state.userInfo.schoolId,
      };
      return this.api.buy.findSemesterList(data).then((res) => {
        that.semesterList = res.data;
        that.semesterPrice = {}; // 清空

        for (let i = 0; i < res.data.length; i++) {
          //给that.semesterPrice 数据里每i个name 赋值 数据里每i个price
          that.semesterPrice[res.data[i].id] = res.data[i].price;
        }

        return Promise.resolve(res.data);
        // Promise 是契约，其实就是异步调用
        // Promise.resolve 是正确时返回，用then处理
        // Promise.reject 是错误时返回，用catch处理错误
      });
    },

    // 获取合同
    getContract() {
      let that = this;
      let data = {
        // name: this.semesterName,
        // number: this.form.number,
        // pay: this.payWay,
        // money: this.presentSemesterPrice * this.form.number,
        schoolId: this.$store.state.userInfo.schoolId,
      };
      return this.api.buy.addContractInfo(data).then((res) => {
        // this.$notice({
        //   message: res.message,
        // }).isShow();
        that.contract = res.data;
        this.contractpop = true;
        return res.data;
      });
    },

    // 更改学期：切换学期时调用
    changeSemester(id, price, payStatus) {
      if (payStatus) {
        return;
      }

      this.form.id = id;
      this.form.price = price;

      const i = this.form.semester.indexOf(id);

      if (i > -1) {
        console.log(this.form, 1);
        this.form.semester.splice(i, 1);
      } else {
        console.log(this.form, 2);
        this.form.semester.push(id);
      }
    },

    // 更改支付方式：切换支付方式时调用
    changePayWat(id) {
      this.form.payWay = id;
    },

    // 立即购买
    buyNow() {
      console.log(this.form.id);
      if (!this.form.semester.length) {
        return this.$notice({
          message: "您还没有选择学期",
        }).isShow();
      }
      if (this.form.number < 1) {
        return this.$notice({
          message: "您还没填写人数",
        }).isShow();
      }
      if (!this.form.payWay) {
        return this.$notice({
          message: "您还没有选择支付方式",
        }).isShow();
      }
      this.getContract();

      this.hidden();
    },

    show() {
      this.visible = true;
    },
    hidden() {
      this.visible = false;
    },
    close() {
      this.visible = false;
      this.$emit("close", this.visible);
    },
  },
  mounted() {
    this.getSemesterList();
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-dialog--center {
  width: 800px;

  .el-form-item {
    margin-bottom: 20px;
  }
}

/deep/ .el-form-item__label {
  color: #333;
  font-size: 16px;
  line-height: 45px;
  margin-right: 14px;
}

/deep/ .el-input--suffix {
  width: 140px;
  color: #333;
}

/deep/ .el-input__inner {
  border: 1px solid #ddd;
  border-radius: 10px;
}

/deep/ .el-dialog__header {
  padding: 0;
  position: relative;
}

/deep/ .el-dialog__headerbtn {
  top: 0.15rem;
  opacity: 0;
}

.upWT {
  height: 50px;
  line-height: 50px;
  background: #b93138;
  color: #fff;
}

.money {
  color: #b93138;
}

.close {
  position: absolute;
  top: 16px;
  right: 20px;
  width: 15px;
  height: 15px;
  background: url(../../../../assets/img/course/Icon_tc_close.png) no-repeat;
  background-size: cover;
}

/deep/ .btnClass {
  color: #333;
  font-size: 16px;
  border: none;
  height: 40px;
  border: 1px solid #b93138;
  border-radius: 20px;
  background: #fff;
}
.semDisabled {
  border: 1px solid #b93138;
  color: #333;
}

.btnClassWidth {
  width: 152px;
  height: 40px;
  color: #888;
  font-size: 16px;
  border: none;
  border: 1px solid #b93138;
  border-radius: 20px;
  background: #fff;
  margin-right: 19px;
}

.active {
  background: url(../../../../assets/img/course/Btn_bg_Sel.png) no-repeat top
    center;
  background-size: 100% 100%;
  width: 152px;
  height: 60px;
  border: none;
  color: #fff;
}

.semesterActive {
  background: linear-gradient(232deg, #b93037, #ef525b);
  box-shadow: 0 3px 6px 0 rgba(255, 67, 77, 0.33);
  border-radius: 20px;
  border: none;
  color: #fff;
}

.moneyTotal {
  color: #333;
  font-size: 16px;
}

.bank {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  margin-top: 20px;
}

.purchaseBtn {
  display: block;
  width: 292px;
  height: 58px;
  margin: 0 auto;
  line-height: 58px;
  border: none;
  background-color: #fff;
  background: url(../../../../assets/img/course/img.png) no-repeat;
  background-size: cover;
  border-radius: 10px;
  color: #fff;
  font-size: 18px;
}

.default {
  color: #888;
  border: 1px solid #ddd;
}
</style>
