<template>
  <div>
    <div class="label">合同订单信息</div>
    <div class="table">
      <el-table
          :data="table.items"
          border
          style="width: 100%" class-name="elTable">
        <el-table-column
            :resizable="false"
            show-overflow-tooltip
            width="180px"
            prop="costOrder.orderid"
            label="订单号">
        </el-table-column>
        <el-table-column
            :resizable="false"
            show-overflow-tooltip
            width="100px"
            prop="costOrder.semestersName"
            label="学期">
        </el-table-column>
        <el-table-column
            :resizable="false"
            width="70px"
            prop="costOrder.number"
            label="幼儿人数">
        </el-table-column>
        <el-table-column
            :resizable="false"
            width="60px"
            prop="costOrder.price"
            label="学费/元">
        </el-table-column>
        <el-table-column
            :resizable="false"
            show-overflow-tooltip
            prop="costOrder.createtime"
            label="购买日期">
          <template slot-scope="scope">
            <div>
              {{ (scope.row.costOrder.createtime) * 1000 | format }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
            :resizable="false"
            show-overflow-tooltip
            prop="endtime"
            label="到期日期">
        </el-table-column>
        <el-table-column
            :resizable="false"
            width="80px"
            label="凭证">
          <template slot-scope="scope">
            <el-button
                v-if="scope.row.intefileBtn == 0 || scope.row.intefileBtn ==1"
                size="mini"
                @click="upload(scope.row.costOrder.id, scope.row.costOrder.ordertype,1)">上传凭证
            </el-button>
            <el-button
                v-if="scope.row.intefileBtn == 2"
                size="mini"
                @click="seeUpload(scope.row.costOrder.id, scope.row.costOrder.ordertype,2)">查看凭证
            </el-button>
          </template>
        </el-table-column>
        <el-table-column
            :resizable="false"
            width="80px"
            label="合同下载">
          <template slot-scope="scope">
            <el-button
                size="mini"
                v-if="scope.row.contractAddress && scope.row.contractAddress.downloadUrl"
                @click="handleView(scope.row.contractAddress.downloadUrl)">合同下载
            </el-button>
            <span v-else>---</span>
          </template>
        </el-table-column>
        <el-table-column
            :resizable="false"
            width="80px"
            label="合同上传">
          <template slot-scope="scope">
            <el-upload
                class="upload-demo"
                name="file"
                :headers="{ token: $store.state.userInfo.token }"
                :action="constant.UPLOAD_URL + '/file/upload'"
                :on-success="(res) => conterUploadSuccess(res, scope.row)"
                :show-file-list="false">
              <button class="courseBtn"
                      v-if="scope.row.contractAddress &&!scope.row.contractAddress.uploadUrl"
                      size="mini">
                上传合同
              </button>
              <button
                  class="courseBtn"
                  v-else-if="scope.row.contractAddress && scope.row.contractAddress.uploadUrl"
                  @click="handleView(scope.row.contractAddress.uploadUrl)">查看
              </button>
              <span v-else>---</span>
            </el-upload>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <comPagination :params="downParams" @changePage="costList"></comPagination>
    <add-invoice ref="addinvoice" @successData='costList' :id="id"></add-invoice>
    <see-invoice ref="seeinvoice" v-if="seeInvoiceShow" :id="id" @close="close"></see-invoice>
    <!-- <upload-proof ref="uploadproof" @submit="costListNesFun"></upload-proof> -->
  </div>
</template>
<script>
import AddInvoice from "@/views/admin/school/classroom/addInvoice";
import UploadProof from "@/views/admin/school/classroom/uploadProof";
import SeeInvoice from "@/views/admin/school/classroom/seeInvoice";
import comPagination from "@/components/admin/pagination";

export default {
  name: "paymentRecords",
  components: {
    SeeInvoice,
    UploadProof,
    AddInvoice,
    comPagination
  },
  // 缴费记录
  data() {
    return {
      table: {
        header: [],
        contractsList: [],
        items: [],
      },
      downParams: {
        page: 1,
        size: 10,
        total: 0,
      },
      seeInvoiceShow: false,
      id: '',
    }
  },
  computed: {
    refreshList() {
      return this.$store.state.refreshList
    }
  },
  watch: {
    refreshList(val) {
      console.log('val', val);
      this.costList()
    }
  },
  methods: {
    // 添加发票数据
    friend(e) {
      this.id = e
      this.$refs.addinvoice.popup();
    },

    // 查看发票数据
    seeFriend(e) {
      this.id = e
      this.seeInvoiceShow = true
    },

    // 上传凭证
    costListNesFun() {
      this.$emit('topFun')
      this.costList()
    },

    // 关闭发票
    close() {
      this.seeInvoiceShow = false;
    },

    // 上传凭证
    upload(orderId = 0, orderType = '', numberStatus) {
      this.$refs.uploadproof.show(orderId, orderType, numberStatus);
    },

    // 查看上传凭证
    seeUpload(orderId = 0, orderType = '', numberStatus) {
      this.$refs.uploadproof.show(orderId, orderType, numberStatus);
    },

    // 合同合并：获取合并后的合同列表
    costList(page = undefined) {
      if (typeof page === 'number') {
        this.downParams.page = page
      }
      var that = this
      let data = {
        userId: this.$store.state.userInfo.userId,
      };
      this.api.admin.findCreateOrderList(this.downParams, data).then((res) => {
        console.log('合同合并', res);
        that.table.items = res.data.list;
        //分页
        that.downParams.total = res.data.total;

        return Promise.resolve(res.data.list.orderid)
      });
    },

    // 合同上传成功
    // 把上传文件得到的链接设置到合同编ID上
    conterUploadSuccess(res, row) {
      console.log('合同上传成功res', res, row)
      //this.uploadPic = res.data.filename
      let self = this
      if (res.flag) {
        this.updateContract(row.contractAddress.contractId, res.data.filename)
            .then(() => {
              this.$notice({
                type: "success",
                message: "上传成功",
              }).isShow();
              self.costList();
            }).catch(e => {
          self.$notice({
            message: e,
          }).isShow();
        })
      } else {
        this.$notice({
          message: res.message,
        }).isShow();
      }
    },


    //合同下载
    handleView(val) {
      console.log('val', val);
      var url2 = "";
      if (val.uploadUrl) {
        url2 = this.constant.URL_admin + "/" + val.uploadUrl;
      } else {
        url2 = this.constant.URL_admin + "/" + val;
      }
      window.location.href = url2;
      console.log('url2', url2);
    },


    //上传合同需要刷新一下列表；
    updateContract(contractId, uploadUrl) {
      let data = {
        contractId,
        uploadUrl
      };
      return this.api.admin.updateContract(data)
    },
  },
  mounted() {
    this.costList();
    // this.getUpdateInfo();
    // this.userIdName();
  },

}
</script>

<style lang="scss" scoped>
.dialog_block {
  padding: 20px 0 10px 0;
}
.courseBtn {
  background: none;
  border: none;
  color: #BA3037;
  font-weight: 500;
  cursor: pointer;
}

.label {
  padding: 24px 0;
  font-size: 24px;
  font-weight: bold;
  color: #BA3037;
}

/deep/ .el-table th {
  background: #BA3037;
  color: #fff;
  border: none;
  text-align: center;
}

/deep/ .el-table--enable-row-transition .el-table__body td {
  text-align: center;
  border: none;
  border-bottom: 1px solid #DDDDDD;
  color: #333;
  font-size: 14px;
  line-height: 42px;
}

/deep/ .el-table .operation {
  width: 150px;

  .operationList {

  }

}

/deep/ .el-button--mini, .el-button--small {
  border: none;
  color: #BA3037;
  font-size: 14px;
  background: none;
}

.table {
  box-shadow: 0 2px 12px 0 rgba(189, 189, 189, 0.41);
}

</style>